
import { defineComponent, onBeforeMount, ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import SideCards from "@/components/DaysAndRequestsShadowCardsComponent.vue";
import token_service from "@/core/services/token_service";
import AccountSettingModal from "@/pages/AccountSettingModal.vue";
import SearchFilters from "@/components/sidebar/SearchFilters.vue";
import Spinner from "@/components/SpinnerComponent.vue";
import SideNavBar from "@/components/SideNavBar.vue";
import ProfileNotValid from "@/components/ProfileNotValidated.vue";
import { defineRulesFor, ability } from "@/core/services/ability";
import { useStore } from "vuex";
import axios from "@/core/services/api";
import axiostcf from "@/core/services/apitcf";

import { getInitData } from "@/core/services/settings";
import { getConfigValue } from "@/core/config";
import { createToaster } from "@meforma/vue-toaster";
// import { useSocketIO } from "@/plugins/socket.io";
// import NotificationUserComponent from "./components/NotificationUserComponent.vue";
// import NavbarNotificationComponent from "./components/notifications/NavbarNotificationComponent.vue";
import { getFileUrlByToken, getNodeEnv } from "@/core/helpers";
import InfluencerOnboardingQuestions from "./components/onboarding/modals/InfluencerOnboardingQuestions.vue";
import router from "@/router";

const AUTH_URL = getConfigValue("AUTH_DOMAINE");

export default defineComponent({
  name: "App",
  components: {
    SideCards,
    AccountSettingModal,
    SearchFilters,
    Spinner,
    SideNavBar,
    ProfileNotValid,
    // NotificationUserComponent,
    // NavbarNotificationComponent,
    InfluencerOnboardingQuestions,
  },
  setup() {
    const openOnboardingModal = ref(false);

    const handleCloseOnboardingModal = (data) => {
      openOnboardingModal.value = data;
    };

    const closeOnboardingModal = ref(handleCloseOnboardingModal);

    // const io = useSocketIO().socket;
    const route = useRoute();
    const store = useStore();
    const toaster = createToaster();
    const node_env = ref("");
    const tcf_auth = ref(process.env.VUE_APP_AUTH_TCF_DOMAINE);
    // refs
    const renderComponent = ref(true);
    const openModal = ref(false);
    let isLoading = ref(true);
    let userInfo: any = ref();
    const isTalent = computed(() => userInfo.value?.role === "TALENT");
    let is_completed = ref(null);
    let is_validated = ref(null);
    let is_verified = ref(null);
    const openJoinUse = ref(false);
    const goToTCF = ref(false);

    // computed
    const isActiveSideNav = computed(() => store.state.global.isActiveSideNav);
    const isSearchHandled = computed(() => store.state.stepper.isSearchHandled);
    const readUserInfo = computed(() => store.state.global.userInfo);
    const isTCFclosed = computed(() => store.state.global.isTCFclosed);
    const read_search_request = computed(() => {
      //search_count.value = store.state.quota.search_count;
      return store.state.quota.search_request;
    });
    const read_max_search_count = computed(() => {
      //max_search_count.value = store.state.quota.max_search_count;
      return store.state.quota.max_search_count;
    });
    const readIsVerified = computed(() => {
      return store.state.global.isVerified;
    });
    const readIsCompleted = computed(() => {
      return store.state.global.isCompleted;
    });
    const readIsValidated = computed(() => {
      return store.state.global.isValidated;
    });
    const picUrl = computed(() => {
      return store.state.global?.userInfo?.profile_efile_id
        ? getFileUrlByToken({
            token: store.state.global.userInfo.profile_efile_id,
          })
        : "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png";
    });

    const isNotificationsActivated = computed(() => {
      let is_notifications_activated;
      if (readUserInfo.value.advertiser) {
        is_notifications_activated =
          readUserInfo.value.advertiser.allow_notifications == "Y"
            ? true
            : false;
      }
      if (readUserInfo.value.influencer) {
        is_notifications_activated =
          readUserInfo.value.influencer.allow_notifications == "Y"
            ? true
            : false;
      }
      return is_notifications_activated;
    });
    /*   const picUrl = computed(() => {
      return (
        store.state.global.profilePicture ||
        "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png"
      );
    }); */
    const searchQuery = computed(() => route.query.q);

    //watch rules
    store.watch(
      (state) => state.global.rules,
      () => {
        ability.update(defineRulesFor());
      },
      {
        deep: true,
      }
    );
    //watch userInfo
    store.watch(
      (state) => state.global.userInfo,
      (data: any) => {
        userInfo.value = data;
      },
      { deep: true }
    );
    //watch isCompleted
    store.watch(
      (state) => state.global.is_completed,
      (data: any) => {
        is_completed.value = data;
      },
      { deep: true }
    );
    //watch isValidated
    store.watch(
      (state) => state.global.is_validated,
      (data: any) => {
        is_validated.value = data;
      },
      { deep: true }
    );
    //watch isVerified
    store.watch(
      (state) => state.global.is_verified,
      (data: any) => {
        is_verified.value = data;
      },
      { deep: true }
    );

    const closeTCFsugg = (type: string) => {
      if (type == "join") {
        openJoinUse.value = false;
        store.commit("global/isTCFclosed", true);
      } else if (type == "go") {
        goToTCF.value = false;
        store.commit("global/isTCFclosed", true);
      }
    };
    // component life cycle
    onBeforeMount(() => {
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);

      tcf_auth.value = process.env.VUE_APP_AUTH_TCF_DOMAINE;

      if (urlParams.has("token")) {
        const token = urlParams.get("token");
        token_service.setToken(token);
      }

      let access = "private" as any;
      let id = 0 as any;
      let query = "";
      if (urlParams.has("access")) {
        access = urlParams.get("access");
        token_service.setAccess(access);
        id = urlParams.get("id");
        query = access == "public" ? `?access=public&id=${id}` : "";
      }

      const mediaQuery = window.matchMedia("(min-width: 768px)");
      if (mediaQuery.matches) {
        store.commit("global/isActiveSideNav", true);
      }

      axios
        .get(`/user/getMe${query}`)
        .then((res) => {
          if (res.status === 200) {
            store.commit("global/userInfo", res.data?.data);
            store.commit("global/rules", res.data?.data?.rules);
            store.commit("global/role", res.data?.data?.role);
            token_service.setRole(res.data?.data?.role);

            userInfo.value = readUserInfo.value;
          }
        })
        .then(() => {
          setTimeout(() => {
            store.commit("global/isVerified", readUserInfo.value.is_verified);
            is_verified.value = readUserInfo.value.is_verified;
            store.commit("global/isCompleted", readUserInfo.value.is_completed);
            is_completed.value = readUserInfo.value.is_completed;
            store.commit("global/isValidated", readUserInfo.value.is_validated);
            is_validated.value = readUserInfo.value.is_validated;
            if (readUserInfo.value.advertiser) {
              store.commit(
                "quota/free_trial_expire_date",
                readUserInfo.value.advertiser.account_validity_to
              );
              store.commit(
                "quota/search_count",
                readUserInfo.value.advertiser.search_count
              );
              store.commit(
                "quota/search_request",
                readUserInfo.value.advertiser.search_request
              );
              store.commit(
                "quota/max_search_count",
                readUserInfo.value.advertiser.max_search_count
              );
              store.commit(
                "quota/account_validity_from",
                readUserInfo.value.advertiser.account_validity_from
              );
            }
            if (
              readUserInfo.value?.role == "TALENT" &&
              readUserInfo.value.influencer &&
              readUserInfo.value.influencer.onboarding_status == "Pending" &&
              readUserInfo.value.onboarding_status == "Pending" &&
              (!readIsCompleted.value || !readIsValidated.value)
            ) {
              openOnboardingModal.value = true;
            } else if (
              readUserInfo.value?.role == "TALENT" &&
              readUserInfo.value.onboarding_status == "Pending" &&
              (!readIsCompleted.value || !readIsValidated.value)
            ) {
              openOnboardingModal.value = true;
            }

            if (
              userInfo.value?.role === "TALENT" &&
              userInfo.value?.tcf_invitation_token &&
              isTCFclosed.value == false &&
              userInfo.value?.show_invitation == true
            ) {
              openJoinUse.value = true;
            }

            if (
              userInfo.value?.role === "TALENT" &&
              userInfo.value?.show_invitation == false &&
              userInfo.value?.tcf_sync_iid
            ) {
              goToTCF.value = true;
            }

            
          }, 600);
        })
        .then(() => {
          setTimeout(() => {
            isLoading.value = false;
          }, 600);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            toaster.show("Session expired", {
              type: "warning",
              position: "bottom",
            });
            const redirectUrl =
              process.env === "local"
                ? "http://localhost:8000"
                : `${getConfigValue("AUTH_DOMAINE")}`;
            token_service.removeAll();
            setTimeout(() => {
              window.location.href = redirectUrl;
            }, 3000);
          }
        });
      getInitData();
    });
    // client server connect to backend using socket io
    /* onMounted(() => {
      //io.connect();
    }); */

    onMounted(async () => {
      node_env.value = getNodeEnv();
    });

    const setLoading = (value: boolean) => {
      isLoading.value = value;
    };

    const logoutHandler = () => {
      isLoading.value = true;
      token_service.removeAll().then(() => (window.location = AUTH_URL));
    };
    const forceRerender = () => {
      renderComponent.value = false;
    };

    const toggleSideNav = () => {
      store.commit("global/isActiveSideNav", !isActiveSideNav.value);
    };
    const openSettingsModal = () => {
      openModal.value = true;
    };

    const redirectToTCF = () => {
      const tcf_auth = process.env.VUE_APP_AUTH_TCF_DOMAINE;
      const externalUrl = `${tcf_auth}login`;
      const newTab = window.open(externalUrl, "_blank");
      if (newTab) {
        newTab.focus();
      }
    };

    return {
      isActiveSideNav,
      isSearchHandled,
      renderComponent,
      openModal,
      isLoading,
      searchQuery,
      userInfo,
      readUserInfo,
      readIsVerified,
      readIsCompleted,
      readIsValidated,
      is_completed,
      is_validated,
      is_verified,
      isTalent,
      getConfigValue,
      setLoading,
      forceRerender,
      logoutHandler,
      toggleSideNav,
      openSettingsModal,
      getFileUrlByToken,
      picUrl,
      isNotificationsActivated,
      read_max_search_count,
      read_search_request,
      openOnboardingModal,
      closeOnboardingModal,
      node_env,
      openJoinUse,
      goToTCF,
      tcf_auth,
      closeTCFsugg,
      redirectToTCF,
    };
  },
});
