
import { defineComponent, ref, computed } from "vue";
import axios from "@/core/services/api";
import Spinner from "@/components/SpinnerComponent.vue";
import { createToaster } from "@meforma/vue-toaster";
import useValidators from "@/core/validations/validators";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getConfigValue } from "@/core/config";

export default defineComponent({
    name: "ContactUsModal",
    components: {
        Spinner,
    },
    props: {
        subject: {
            type: String,
            required: false
        },
        category: {
            type: String,
            required: false
        },
        openContactModal: {
            type: Boolean,
            required: false
        }
    },
    setup(props, { emit }) {
        const { isEmpty } = useValidators();
        const toaster = createToaster();
        const router = useRouter()
        const userInfo = computed(() => store.state.global.userInfo);
        const store = useStore();
        const active_nav = ref('choice')
        const errors = ref({
            subject: "",
            body: "",
        });
        const formData = ref({
            recipient: "support@tawa.digital",
            subject: props.subject || undefined,
            body: undefined,
            category: props.category
        });

        const click = ref({
            advertiser_id: userInfo.value.advertiser_id,
            role: userInfo.value.role,
            module: 'BOOK_DEMO',
            click: 'More Requests',
        });

        const isTawaAgencyUser = ref(getConfigValue("TAWA_AGENCY_EMAILS").includes(userInfo.value.user_email))

        const isLoading = ref(false);

        const validate = (arg = "") => {
            if (arg === "subject") {
                errors.value.subject = isEmpty("subject", formData.value.subject);
            } else if (arg === "body") {
                errors.value.body = isEmpty("body", formData.value.body);
            } else {
                errors.value.body = isEmpty("body", formData.value.body);
                errors.value.subject = isEmpty("subject", formData.value.subject);
            }
        };
        const change_active_nav = () => {
            active_nav.value = 'contact'

        }
        const cancel = () => {

            emit("closeModal");
        }
        const goToBookDemo = () => {
            axios
                .post(`/user-click/create`, click.value)
                .then((res) => {
                    emit('closeModal');
                    router.push(`/FreemiumUpdateProfile`)
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
        const submit = () => {
            validate();
            if (!errors.value?.subject && !errors.value?.body) {
                isLoading.value = true;
                axios.post("/contact/contact-us", formData.value).then((res) => {
                    if (res.status === 200) {
                        isLoading.value = false
                        toaster.show("Contacted successfully!", {
                            type: "info",
                            position: "bottom",
                        });
                        window.location.reload();
                    }
                }).catch(err => {
                    isLoading.value = false
                    toaster.show("Something gone wrong!", {
                        type: "danger",
                        position: "bottom",
                    });
                })
            }
        };

        return {
            isLoading,
            formData,
            errors,
            isEmpty,
            validate,
            submit, active_nav, change_active_nav, goToBookDemo, cancel, userInfo, isTawaAgencyUser
        };
    },
});
