
import {
  defineComponent,
  ref,
  computed,
  onBeforeMount,
  watch,
  onMounted,
} from "vue";
import { useAbility } from "@casl/vue";
import { useStore } from "vuex";
import {
  getFileUrlByToken,
  getNodeEnv,
  isMediaQueryMobile,
} from "@/core/helpers";
import token_service from "@/core/services/token_service";
import { getConfigValue } from "@/core/config";
import axios from "@/core/services/api";
export default defineComponent({
  name: "SideNavBar",
  components: {
    // ContactUsModal,
  },
  setup(_, { emit }) {
    const store = useStore();
    const openContactModal = ref(false);
    const unread_notifications = ref({
      applications_notifications: 0,
      performance_notifications: 0,
      planning_notifications: 0,
      campaigns_notifications: 0,
      contracts_notifications: 0,
      quotes_notifications: 0,
      briefs_notifications: 0,
      content_approval_notifications: 0,
      content_review_notifications: 0,
      collaborations_notifications: 0,
      invitations_notifications: 0,
    } as any);
    const userInfo = computed(() => store.state.global.userInfo);
    const isActiveSideNav = computed(() => store.state.global.isActiveSideNav);
    const notificationsCount = ref(store.state.global.notificationsCount);
    const node_env = ref("");

    const picUrl = computed(() => {
      return store.state.global?.userInfo?.profile_efile_id
        ? getFileUrlByToken({
            token: store.state.global.userInfo.profile_efile_id,
          })
        : "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png";
    });
    const isContactActive = getConfigValue("CONTACT") === "true";
    const isAdvertiser = computed(
      () =>
        userInfo.value?.role === "AGENCY" || userInfo.value?.role === "BRAND"
    );
    const ability = useAbility();
    const activeSidebar = ref(true);
    const sideLinks = ref([
      /* {
        title: "Home",
        href: "/",
        icon: "home",
        rules: {
          action: "manage",
          subject: "Home",
          roles: ["TALENT"],
        },
      }, */
      // {
      //   title: "Opportunities",
      //   href: "/opportunities",
      //   icon: "opportunities",
      //   rules: {
      //     action: "read",
      //     subject: "Home",
      //     roles: ["TALENT"],
      //   },
      //   notifications: false,
      //   module: "OPPORTUNITIES",
      //   is_tawa_users_only: true
      // },
      // {
      //   title: "Saved Opportunities",
      //   href: "/saved-opportunities",
      //   icon: "bookmark",
      //   rules: {
      //     action: "read",
      //     subject: "Home",
      //     roles: ["TALENT"],
      //   },
      //   notifications: false,
      //   module: "SAVED_OPPORTUNITIES",
      //   is_tawa_users_only: true
      // },
      // {
      //   title: "Applications",
      //   href: "/my-applications",
      //   icon: "app",
      //   rules: {
      //     action: "read",
      //     subject: "Home",
      //     roles: ["TALENT"],
      //   },
      //   notifications: unread_notifications.value.applications_notifications,
      //   module: "APPLICATIONS",
      //   is_tawa_users_only: true
      // },
      {
        title: "Invitations",
        href: "/invitations",
        icon: "invitations",
        rules: {
          action: "manage",
          subject: "Home",
          roles: ["TALENT"],
        },
        notifications: unread_notifications.value.invitations_notifications,
        module: "INVITATIONS",
        is_tawa_users_only: true,
      },
      {
        title: "My Collaborations",
        href: "/collaborations",
        icon: "collaborations",
        rules: {
          action: "manage",
          subject: "Home",
          roles: ["TALENT"],
        },
        notifications: unread_notifications.value.collaborations_notifications,
        module: "COLLABORATIONS",
        is_tawa_users_only: true,
      },
      {
        title: "Discover Talents",
        href: "/discover",
        icon: "discover",
        rules: {
          action: "read",
          subject: "Discover",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: false,
        module: "DISCOVER",
        is_tawa_users_only: false,
      },
      {
        title: "Search by Profile",
        href: "/search",
        icon: "search",
        rules: {
          action: "read",
          subject: "Discover",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: false,
        module: "SEARCH",
        is_tawa_users_only: false,
      },
      {
        title: "Calculate Eng. rate",
        href: "/calculate-eng-rate",
        icon: "Percent",
        rules: {
          action: "manage",
          subject: "CalculateEngagementRate",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: false,
        module: "ENGAGEMENT_RATE",
        is_tawa_users_only: false,
      },
      {
        title: "Favorite creators",
        href: "/favorite",
        icon: "favorite",
        rules: {
          action: "manage",
          subject: "Favorite",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: false,
        module: "FAVORITES",
        is_tawa_users_only: false,
      },
      {
        title: "Shortlists",
        href: "/short-lists",
        icon: "bookmark",
        rules: {
          action: "manage",
          subject: "Favorite",
          roles: ["AGENCY", "BRAND"],
        }, //5
        notifications: false,
        module: "SHORTLISTS",
        is_tawa_users_only: false,
      },
      {
        title: "My Brands",
        href: "/brands",
        icon: "brands",
        rules: {
          action: "manage",
          subject: "Brands",
          roles: ["AGENCY"],
        }, //5
        notifications: false,
        module: "BRANDS",
        is_tawa_users_only: true,
      },
      {
        title: "My Clients",
        href: "/clients",
        icon: "clients",
        rules: {
          action: "manage",
          subject: "Clients",
          roles: ["AGENCY"],
        }, //5
        notifications: false,
        module: "BRANDS",
        is_tawa_users_only: true,
      },
      {
        title: "Direct inbox",
        href: "/direct/inbox",
        icon: "inbox",
        rules: {
          action: "manage",
          subject: "Favorite",
          roles: ["AGENCY", "BRAND", "TALENT"],
        }, //6
        notifications: false,
        module: "INBOX",
      },
      {
        title: "My Campaigns",
        href: "/campaigns",
        icon: "camp",
        rules: {
          action: "manage",
          subject: "Campaign",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: false,
        module: "CAMPAIGNS",
        is_tawa_users_only: true,
      },
      {
        title: "Quotes",
        href: "/quotes",
        icon: "quote",
        rules: {
          action: "manage",
          subject: "Quotes",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: unread_notifications.value.quotes_notifications,
        module: "QUOTES",
        is_tawa_users_only: true,
      },
      // {
      //   title: "Applications",
      //   href: "/applications",
      //   icon: "application",
      //   rules: {
      //     action: "manage",
      //     subject: "Applications",
      //     roles: ["AGENCY", "BRAND"],
      //   },
      //   notifications: unread_notifications.value.applications_notifications,
      //   module: "APPLICATIONS",
      //   is_tawa_users_only: true
      // },
      {
        title: "Contracts",
        href: "/contracts",
        icon: "contract_sidebar",
        rules: {
          action: "manage",
          subject: "Contracts",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: unread_notifications.value.contracts_notifications,
        module: "CONTRACTS",
        is_tawa_users_only: true,
      },
      {
        title: "Contracts",
        href: "/my-contracts",
        icon: "contract_sidebar",
        rules: {
          action: "manage",
          subject: "Contracts",
          roles: ["TALENT"],
        },
        notifications: unread_notifications.value.contracts_notifications,
        module: "CONTRACTS",
        is_tawa_users_only: true,
      },
      {
        title: "Briefs",
        href: "/briefs",
        icon: "brief_sidebar",
        rules: {
          action: "manage",
          subject: "Briefs",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: unread_notifications.value.briefs_notifications,
        module: "BRIEFS",
        is_tawa_users_only: true,
      },
      {
        title: "Briefs",
        href: "/my-briefs",
        icon: "brief_sidebar",
        rules: {
          action: "manage",
          subject: "Briefs",
          roles: ["TALENT"],
        },
        notifications: unread_notifications.value.briefs_notifications,
        module: "BRIEFS",
        is_tawa_users_only: true,
      },
      {
        title: "Planning",
        href: "/planning",
        icon: "planning",
        rules: {
          action: "manage",
          subject: "Planning",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: unread_notifications.value.planning_notifications,
        module: "PLANNING",
        is_tawa_users_only: true,
      },
      {
        title: "Planning",
        href: "/my-planning",
        icon: "planning",
        rules: {
          action: "manage",
          subject: "Planning",
          roles: ["TALENT"],
        },
        notifications: unread_notifications.value.planning_notifications,
        module: "PLANNING",
        is_tawa_users_only: true,
      },
      {
        title: "Content Approval",
        href: "/content-approval",
        icon: "content",
        rules: {
          action: "manage",
          subject: "ContentApproval",
          roles: ["TALENT"],
        },
        notifications:
          unread_notifications.value.content_approval_notifications,
        module: "CONTENT_APPROVAL",
        is_tawa_users_only: true,
      },
      {
        title: "Content Review",
        href: "/content-review",
        icon: "content",
        rules: {
          action: "manage",
          subject: "ContentReview",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: unread_notifications.value.content_review_notifications,
        module: "CONTENT_REVIEW",
        is_tawa_users_only: true,
      },
      {
        title: "Performance",
        href: "/my-performance",
        icon: "performance",
        rules: {
          action: "manage",
          subject: "Performance",
          roles: ["TALENT"],
        },
        notifications: unread_notifications.value.performance_notifications,
        module: "PERFORMANCE",
        is_tawa_users_only: true,
      },
      {
        title: "Performance",
        href: "/performance/campaign",
        icon: "performance",
        rules: {
          action: "manage",
          subject: "Performance",
          roles: ["AGENCY", "BRAND"],
        },
        notifications: unread_notifications.value.performance_notifications,
        module: "PERFORMANCE",
        is_tawa_users_only: true,
      },
      {
        title: "My profile",
        href: "/profile",
        icon: "profile",
        rules: {
          action: "manage",
          subject: "Home",
          roles: ["TALENT"],
        },
        module: "PROFILE",
        is_tawa_users_only: false,
      },
      {
        title: "Contact Us",
        href: "/contact-us",
        icon: "contact",
        rules: {
          action: "manage",
          subject: "Home",
          roles: ["TALENT", "AGENCY", "BRAND"],
        },
        module: "CONTACT",
        is_tawa_users_only: false,
      },
    ]);

    onMounted(() => {
      node_env.value = getNodeEnv();
    });

    watch(
      () => unread_notifications.value,
      (newValue) => {
        updateSideLinks(newValue);
      },
      { deep: true }
    );

    const updateSideLinks = (countObject) => {
      sideLinks.value.forEach((sideLink) => {
        if (
          Object.prototype.hasOwnProperty.call(
            countObject,
            `${sideLink.module.toLowerCase()}_notifications`
          )
        ) {
          sideLink.notifications =
            countObject[`${sideLink.module.toLowerCase()}_notifications`];
        }
      });
    };
    // sideLinks.value = getConfigValue("CHAT") === "true" ? sideLinks : sideLinks.value.filter((side_link)=>side_link.href !== "/campaigns");
    // getConfigValue("SHORTLIST") === "true"
    //   ? sideLinks
    //   : sideLinks.value.splice(5, 1);

    // getConfigValue("CAMPAIGN") === "true"
    //   ? sideLinks
    //   : sideLinks.value.filter((side_link)=>side_link.href !== "/campaigns");
    sideLinks.value =
      getConfigValue("CHAT") === "true"
        ? sideLinks.value
        : sideLinks.value.filter(
            (side_link) => side_link.href !== "/direct/inbox"
          );
    sideLinks.value =
      getConfigValue("SHORTLIST") === "true"
        ? sideLinks.value
        : sideLinks.value.filter(
            (side_link) => side_link.href !== "/short-lists"
          );
    sideLinks.value =
      getConfigValue("CAMPAIGN") === "true"
        ? sideLinks.value
        : sideLinks.value.filter(
            (side_link) => side_link.href !== "/campaigns"
          );
    sideLinks.value =
      getConfigValue("TAWA_AGENCY_EMAILS")
        .split(",")
        .includes(userInfo.value.user_email) === true
        ? sideLinks.value
        : sideLinks.value.filter(
            (side_link) => side_link.is_tawa_users_only == false
          );

    const openSettingsModal = () => {
      emit("update:modelValue", true);
    };

    const showContactModal = () => {
      if (openContactModal.value === true) {
        openContactModal.value = false;
      } else {
        openContactModal.value = true;
      }
    };

    const toggleSideNav = () => {
      store.commit("global/isActiveSideNav", !isActiveSideNav.value);
    };

    const menuLinkClicked = (href: string) => {
      if (isMediaQueryMobile()) {
        store.commit("global/isActiveSideNav", !isActiveSideNav.value);
      }
      const module_index = sideLinks.value.findIndex(
        (item) => item.href === href
      );
      if (module_index !== -1) {
        sideLinks.value[module_index].notifications = false;
      }
    };

    const markModuleNotificationsAsRead = async (module: string) => {
      try {
        const response = await axios.get(
          `/notifications/module/${module}/read`
        );
        if (response.status === 200) {
          fetchcountsNotifications();
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    };

    onBeforeMount(() => {
      // fetchcountsNotifications()
    });

    const fetchcountsNotifications = async () => {
      try {
        const response = await axios.get(`/notifications/modules/unread`);
        if (response.status === 200) {
          unread_notifications.value = response.data;
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    };

    // store.watch(
    //   (state) => state.global.notificationsCount,
    //   async (data: any) => {
    //     notificationsCount.value = data;
    //     await fetchcountsNotifications();
    //   },
    //   { deep: true }
    // );

    return {
      userInfo,
      picUrl,
      ability,
      activeSidebar,
      sideLinks,
      openContactModal,
      token_service,
      isContactActive,
      openSettingsModal,
      toggleSideNav,
      menuLinkClicked,
      showContactModal,
      getConfigValue,
      isAdvertiser,
      node_env,
    };
  },
});
