import { ref } from "vue";

import StepOne from "@/components/discover-steps/GenderStep.vue";
import StepTwo from "@/components/discover-steps/AgeStep.vue";
import StepFour from "@/components/discover-steps/ResidenceStep.vue";
import StepFive from "@/components/discover-steps/FollowersCountStep.vue";

export const sortFilters = [
    {
        id: 't.categories @> Array[52]',
        text: 'Pertinence'
    },
    {
        id: 'd.followers_count',
        text: 'Followers Count'
    },
    // {
    //     id: 'd.average_video_views',
    //     text: 'Average video views'
    // },
    {
        id: 'd.average_likes',
        text: 'Active community'
    },
    {
        id: 'd.engagement_rate',
        text: 'Engagement Rate'
    }
]


export const search_sortFilters = [
    {
        id: 'followers_count',
        text: 'Followers Count'
    },
    // {
    //     id: 'average_video_views',
    //     text: 'Average video views'
    // },
    {
        id: 'average_likes',
        text: 'Active community'
    },
    {
        id: 'engagement_rate',
        text: 'Engagement Rate'
    }
]

export const steps = [
    {
        title: "Gender",
        subtitle: "Subtitle sample",
        component: StepOne
    },
    {
        title: "Age",
        subtitle: "Subtitle sample",
        component: StepTwo,
        completed: false
    },
    {
        title: "Location",
        subtitle: "Subtitle sample",
        component: StepFour,
        completed: false
    },
    {
        title: "Followers Count",
        subtitle: "Subtitle sample",
        component: StepFive,
        completed: false
    },
]

export const countries = [
    {
        "label": "Afghanistan",
        "value": "AF",
        "icon": "af",
        "iso_3": "AFG",
        "prefix": 93,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Åland Islands",
        "value": "AX",
        "icon": "ax",
        "iso_3": "ALA",
        "prefix": 358,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Albania",
        "value": "AL",
        "icon": "al",
        "iso_3": "ALB",
        "prefix": 355,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Algeria",
        "value": "DZ",
        "icon": "dz",
        "iso_3": "DZA",
        "prefix": 213,
        "region": "Africa",
        "sub_region": "Northern Africa"
    },
    {
        "label": "American Samoa",
        "value": "AS",
        "icon": "as",
        "iso_3": "ASM",
        "prefix": 1684,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Andorra",
        "value": "AD",
        "icon": "ad",
        "iso_3": "AND",
        "prefix": 376,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Angola",
        "value": "AO",
        "icon": "ao",
        "iso_3": "AGO",
        "prefix": 244,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Anguilla",
        "value": "AI",
        "icon": "ai",
        "iso_3": "AIA",
        "prefix": 1264,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    // {
    //   "label": "Antarctica",
    //   "value": "AQ",
    //   "icon": "aq",
    //   "iso_3": "ATA",
    //   "prefix": 672,
    //   "region": "",
    //   "sub_region": ""
    // },
    {
        "label": "Antigua and Barbuda",
        "value": "AG",
        "icon": "ag",
        "iso_3": "ATG",
        "prefix": 1268,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Argentina",
        "value": "AR",
        "icon": "ar",
        "iso_3": "ARG",
        "prefix": 54,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Armenia",
        "value": "AM",
        "icon": "am",
        "iso_3": "ARM",
        "prefix": 374,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Aruba",
        "value": "AW",
        "icon": "aw",
        "iso_3": "ABW",
        "prefix": 297,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Australia",
        "value": "AU",
        "icon": "au",
        "iso_3": "AUS",
        "prefix": 61,
        "region": "Oceania",
        "sub_region": "Australia and New Zealand"
    },
    {
        "label": "Austria",
        "value": "AT",
        "icon": "at",
        "iso_3": "AUT",
        "prefix": 43,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "Azerbaijan",
        "value": "AZ",
        "icon": "az",
        "iso_3": "AZE",
        "prefix": 994,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Bahamas",
        "value": "BS",
        "icon": "bs",
        "iso_3": "BHS",
        "prefix": 1242,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Bahrain",
        "value": "BH",
        "icon": "bh",
        "iso_3": "BHR",
        "prefix": 973,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Bangladesh",
        "value": "BD",
        "icon": "bd",
        "iso_3": "BGD",
        "prefix": 880,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Barbados",
        "value": "BB",
        "icon": "bb",
        "iso_3": "BRB",
        "prefix": 1246,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Belarus",
        "value": "BY",
        "icon": "by",
        "iso_3": "BLR",
        "prefix": 375,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Belgium",
        "value": "BE",
        "icon": "be",
        "iso_3": "BEL",
        "prefix": 32,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "Belize",
        "value": "BZ",
        "icon": "bz",
        "iso_3": "BLZ",
        "prefix": 501,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Benin",
        "value": "BJ",
        "icon": "bj",
        "iso_3": "BEN",
        "prefix": 229,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Bermuda",
        "value": "BM",
        "icon": "bm",
        "iso_3": "BMU",
        "prefix": 1441,
        "region": "Americas",
        "sub_region": "Northern America"
    },
    {
        "label": "Bhutan",
        "value": "BT",
        "icon": "bt",
        "iso_3": "BTN",
        "prefix": 975,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Bolivia, Plurinational State of",
        "value": "BO",
        "icon": "bo",
        "iso_3": "BOL",
        "prefix": 591,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    // {
    //   "label": "Bonaire, Sint Eustatius and Saba",
    //   "value": "BQ",
    //   "icon": "bq",
    //   "iso_3": "BES",
    //   "prefix": 599,
    //   "region": "Americas",
    //   "sub_region": "Latin America and the Caribbean"
    // },
    {
        "label": "Bosnia and Herzegovina",
        "value": "BA",
        "icon": "ba",
        "iso_3": "BIH",
        "prefix": 387,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Botswana",
        "value": "BW",
        "icon": "bw",
        "iso_3": "BWA",
        "prefix": 267,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Bouvet Island",
        "value": "BV",
        "icon": "bv",
        "iso_3": "BVT",
        "prefix": 47,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Brazil",
        "value": "BR",
        "icon": "br",
        "iso_3": "BRA",
        "prefix": 55,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "British Indian Ocean Territory",
        "value": "IO",
        "icon": "io",
        "iso_3": "IOT",
        "prefix": 1284,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Brunei Darussalam",
        "value": "BN",
        "icon": "bn",
        "iso_3": "BRN",
        "prefix": 673,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Bulgaria",
        "value": "BG",
        "icon": "bg",
        "iso_3": "BGR",
        "prefix": 359,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Burkina Faso",
        "value": "BF",
        "icon": "bf",
        "iso_3": "BFA",
        "prefix": 226,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Burundi",
        "value": "BI",
        "icon": "bi",
        "iso_3": "BDI",
        "prefix": 257,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Cabo Verde",
        "value": "CV",
        "icon": "cv",
        "iso_3": "CPV",
        "prefix": 238,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Cambodia",
        "value": "KH",
        "icon": "kh",
        "iso_3": "KHM",
        "prefix": 855,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Cameroon",
        "value": "CM",
        "icon": "cm",
        "iso_3": "CMR",
        "prefix": 237,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Canada",
        "value": "CA",
        "icon": "ca",
        "iso_3": "CAN",
        "prefix": 1,
        "region": "Americas",
        "sub_region": "Northern America"
    },
    {
        "label": "Cayman Islands",
        "value": "KY",
        "icon": "ky",
        "iso_3": "CYM",
        "prefix": 1345,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Central African Republic",
        "value": "CF",
        "icon": "cf",
        "iso_3": "CAF",
        "prefix": 236,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Chad",
        "value": "TD",
        "icon": "td",
        "iso_3": "TCD",
        "prefix": 235,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Chile",
        "value": "CL",
        "icon": "cl",
        "iso_3": "CHL",
        "prefix": 56,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "China",
        "value": "CN",
        "icon": "cn",
        "iso_3": "CHN",
        "prefix": 86,
        "region": "Asia",
        "sub_region": "Eastern Asia"
    },
    {
        "label": "Christmas Island",
        "value": "CX",
        "icon": "cx",
        "iso_3": "CXR",
        "prefix": 61,
        "region": "Oceania",
        "sub_region": "Australia and New Zealand"
    },
    {
        "label": "Cocos (Keeling) Islands",
        "value": "CC",
        "icon": "cc",
        "iso_3": "CCK",
        "prefix": 61,
        "region": "Oceania",
        "sub_region": "Australia and New Zealand"
    },
    {
        "label": "Colombia",
        "value": "CO",
        "icon": "co",
        "iso_3": "COL",
        "prefix": 57,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Comoros",
        "value": "KM",
        "icon": "km",
        "iso_3": "COM",
        "prefix": 269,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Congo",
        "value": "CG",
        "icon": "cg",
        "iso_3": "COG",
        "prefix": 242,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Congo, Democratic Republic of the",
        "value": "CD",
        "icon": "cd",
        "iso_3": "COD",
        "prefix": 242,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Cook Islands",
        "value": "CK",
        "icon": "ck",
        "iso_3": "COK",
        "prefix": 682,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Costa Rica",
        "value": "CR",
        "icon": "cr",
        "iso_3": "CRI",
        "prefix": 506,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Côte d'Ivoire",
        "value": "CI",
        "icon": "ci",
        "iso_3": "CIV",
        "prefix": 225,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Croatia",
        "value": "HR",
        "icon": "hr",
        "iso_3": "HRV",
        "prefix": 385,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Cuba",
        "value": "CU",
        "icon": "cu",
        "iso_3": "CUB",
        "prefix": 53,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Curaçao",
        "value": "CW",
        "icon": "cw",
        "iso_3": "CUW",
        "prefix": 599,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Cyprus",
        "value": "CY",
        "icon": "cy",
        "iso_3": "CYP",
        "prefix": 357,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Czechia",
        "value": "CZ",
        "icon": "cz",
        "iso_3": "CZE",
        "prefix": 420,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Denmark",
        "value": "DK",
        "icon": "dk",
        "iso_3": "DNK",
        "prefix": 45,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Djibouti",
        "value": "DJ",
        "icon": "dj",
        "iso_3": "DJI",
        "prefix": 253,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Dominica",
        "value": "DM",
        "icon": "dm",
        "iso_3": "DMA",
        "prefix": 1767,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Dominican Republic",
        "value": "DO",
        "icon": "do",
        "iso_3": "DOM",
        "prefix": 1809,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Ecuador",
        "value": "EC",
        "icon": "ec",
        "iso_3": "ECU",
        "prefix": 593,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Egypt",
        "value": "EG",
        "icon": "eg",
        "iso_3": "EGY",
        "prefix": 20,
        "region": "Africa",
        "sub_region": "Northern Africa"
    },
    {
        "label": "El Salvador",
        "value": "SV",
        "icon": "sv",
        "iso_3": "SLV",
        "prefix": 503,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Equatorial Guinea",
        "value": "GQ",
        "icon": "gq",
        "iso_3": "GNQ",
        "prefix": 240,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Eritrea",
        "value": "ER",
        "icon": "er",
        "iso_3": "ERI",
        "prefix": 291,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Estonia",
        "value": "EE",
        "icon": "ee",
        "iso_3": "EST",
        "prefix": 372,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Eswatini",
        "value": "SZ",
        "icon": "sz",
        "iso_3": "SWZ",
        "prefix": 268,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Ethiopia",
        "value": "ET",
        "icon": "et",
        "iso_3": "ETH",
        "prefix": 251,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Falkland Islands (Malvinas)",
        "value": "FK",
        "icon": "fk",
        "iso_3": "FLK",
        "prefix": 500,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Faroe Islands",
        "value": "FO",
        "icon": "fo",
        "iso_3": "FRO",
        "prefix": 298,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Fiji",
        "value": "FJ",
        "icon": "fj",
        "iso_3": "FJI",
        "prefix": 679,
        "region": "Oceania",
        "sub_region": "Melanesia"
    },
    {
        "label": "Finland",
        "value": "FI",
        "icon": "fi",
        "iso_3": "FIN",
        "prefix": 358,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "France",
        "value": "FR",
        "icon": "fr",
        "iso_3": "FRA",
        "prefix": 33,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "French Guiana",
        "value": "GF",
        "icon": "gf",
        "iso_3": "GUF",
        "prefix": 594,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "French Polynesia",
        "value": "PF",
        "icon": "pf",
        "iso_3": "PYF",
        "prefix": 689,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    // {
    //   "label": "French Southern Territories",
    //   "value": "TF",
    //   "icon": "tf",
    //   "iso_3": "ATF",
    //   "prefix": 262,
    //   "region": "Africa",
    //   "sub_region": "Sub-Saharan Africa"
    // },
    {
        "label": "Gabon",
        "value": "GA",
        "icon": "ga",
        "iso_3": "GAB",
        "prefix": 241,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Gambia",
        "value": "GM",
        "icon": "gm",
        "iso_3": "GMB",
        "prefix": 220,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Georgia",
        "value": "GE",
        "icon": "ge",
        "iso_3": "GEO",
        "prefix": 995,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Germany",
        "value": "DE",
        "icon": "de",
        "iso_3": "DEU",
        "prefix": 49,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "Ghana",
        "value": "GH",
        "icon": "gh",
        "iso_3": "GHA",
        "prefix": 233,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Gibraltar",
        "value": "GI",
        "icon": "gi",
        "iso_3": "GIB",
        "prefix": 350,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Greece",
        "value": "GR",
        "icon": "gr",
        "iso_3": "GRC",
        "prefix": 30,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Greenland",
        "value": "GL",
        "icon": "gl",
        "iso_3": "GRL",
        "prefix": 299,
        "region": "Americas",
        "sub_region": "Northern America"
    },
    {
        "label": "Grenada",
        "value": "GD",
        "icon": "gd",
        "iso_3": "GRD",
        "prefix": 1473,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Guadeloupe",
        "value": "GP",
        "icon": "gp",
        "iso_3": "GLP",
        "prefix": 590,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Guam",
        "value": "GU",
        "icon": "gu",
        "iso_3": "GUM",
        "prefix": 1671,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Guatemala",
        "value": "GT",
        "icon": "gt",
        "iso_3": "GTM",
        "prefix": 502,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Guernsey",
        "value": "GG",
        "icon": "gg",
        "iso_3": "GGY",
        "prefix": 44,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Guinea",
        "value": "GN",
        "icon": "gn",
        "iso_3": "GIN",
        "prefix": 224,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Guinea-Bissau",
        "value": "GW",
        "icon": "gw",
        "iso_3": "GNB",
        "prefix": 245,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Guyana",
        "value": "GY",
        "icon": "gy",
        "iso_3": "GUY",
        "prefix": 592,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Haiti",
        "value": "HT",
        "icon": "ht",
        "iso_3": "HTI",
        "prefix": 509,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Heard Island and McDonald Islands",
        "value": "HM",
        "icon": "hm",
        "iso_3": "HMD",
        "prefix": 334,
        "region": "Oceania",
        "sub_region": "Australia and New Zealand"
    },
    {
        "label": "Holy See",
        "value": "VA",
        "icon": "va",
        "iso_3": "VAT",
        "prefix": 379,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Honduras",
        "value": "HN",
        "icon": "hn",
        "iso_3": "HND",
        "prefix": 504,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Hong Kong",
        "value": "HK",
        "icon": "hk",
        "iso_3": "HKG",
        "prefix": 852,
        "region": "Asia",
        "sub_region": "Eastern Asia"
    },
    {
        "label": "Hungary",
        "value": "HU",
        "icon": "hu",
        "iso_3": "HUN",
        "prefix": 36,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Iceland",
        "value": "IS",
        "icon": "is",
        "iso_3": "ISL",
        "prefix": 354,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "India",
        "value": "IN",
        "icon": "in",
        "iso_3": "IND",
        "prefix": 91,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Indonesia",
        "value": "ID",
        "icon": "id",
        "iso_3": "IDN",
        "prefix": 62,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Iran, Islamic Republic of",
        "value": "IR",
        "icon": "ir",
        "iso_3": "IRN",
        "prefix": 98,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Iraq",
        "value": "IQ",
        "icon": "iq",
        "iso_3": "IRQ",
        "prefix": 964,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Ireland",
        "value": "IE",
        "icon": "ie",
        "iso_3": "IRL",
        "prefix": 353,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Isle of Man",
        "value": "IM",
        "icon": "im",
        "iso_3": "IMN",
        "prefix": 44,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Israel",
        "value": "IL",
        "icon": "il",
        "iso_3": "ISR",
        "prefix": 972,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Italy",
        "value": "IT",
        "icon": "it",
        "iso_3": "ITA",
        "prefix": 39,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Jamaica",
        "value": "JM",
        "icon": "jm",
        "iso_3": "JAM",
        "prefix": 1876,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Japan",
        "value": "JP",
        "icon": "jp",
        "iso_3": "JPN",
        "prefix": 81,
        "region": "Asia",
        "sub_region": "Eastern Asia"
    },
    {
        "label": "Jersey",
        "value": "JE",
        "icon": "je",
        "iso_3": "JEY",
        "prefix": 44,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Jordan",
        "value": "JO",
        "icon": "jo",
        "iso_3": "JOR",
        "prefix": 862,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Kazakhstan",
        "value": "KZ",
        "icon": "kz",
        "iso_3": "KAZ",
        "prefix": 7,
        "region": "Asia",
        "sub_region": "Central Asia"
    },
    {
        "label": "Kenya",
        "value": "KE",
        "icon": "ke",
        "iso_3": "KEN",
        "prefix": 254,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Kiribati",
        "value": "KI",
        "icon": "ki",
        "iso_3": "KIR",
        "prefix": 686,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Korea, Democratic People's Republic of",
        "value": "KP",
        "icon": "kp",
        "iso_3": "PRK",
        "prefix": 850,
        "region": "Asia",
        "sub_region": "Eastern Asia"
    },
    {
        "label": "Korea, Republic of",
        "value": "KR",
        "icon": "kr",
        "iso_3": "KOR",
        "prefix": 82,
        "region": "Asia",
        "sub_region": "Eastern Asia"
    },
    {
        "label": "Kuwait",
        "value": "KW",
        "icon": "kw",
        "iso_3": "KWT",
        "prefix": 965,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Kyrgyzstan",
        "value": "KG",
        "icon": "kg",
        "iso_3": "KGZ",
        "prefix": 996,
        "region": "Asia",
        "sub_region": "Central Asia"
    },
    {
        "label": "Lao People's Democratic Republic",
        "value": "LA",
        "icon": "la",
        "iso_3": "LAO",
        "prefix": 856,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Latvia",
        "value": "LV",
        "icon": "lv",
        "iso_3": "LVA",
        "prefix": 371,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Lebanon",
        "value": "LB",
        "icon": "lb",
        "iso_3": "LBN",
        "prefix": 961,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Lesotho",
        "value": "LS",
        "icon": "ls",
        "iso_3": "LSO",
        "prefix": 266,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Liberia",
        "value": "LR",
        "icon": "lr",
        "iso_3": "LBR",
        "prefix": 231,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Libya",
        "value": "LY",
        "icon": "ly",
        "iso_3": "LBY",
        "prefix": 218,
        "region": "Africa",
        "sub_region": "Northern Africa"
    },
    {
        "label": "Liechtenstein",
        "value": "LI",
        "icon": "li",
        "iso_3": "LIE",
        "prefix": 423,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "Lithuania",
        "value": "LT",
        "icon": "lt",
        "iso_3": "LTU",
        "prefix": 370,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Luxembourg",
        "value": "LU",
        "icon": "lu",
        "iso_3": "LUX",
        "prefix": 352,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "Macao",
        "value": "MO",
        "icon": "mo",
        "iso_3": "MAC",
        "prefix": 853,
        "region": "Asia",
        "sub_region": "Eastern Asia"
    },
    {
        "label": "Madagascar",
        "value": "MG",
        "icon": "mg",
        "iso_3": "MDG",
        "prefix": 261,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Malawi",
        "value": "MW",
        "icon": "mw",
        "iso_3": "MWI",
        "prefix": 265,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Malaysia",
        "value": "MY",
        "icon": "my",
        "iso_3": "MYS",
        "prefix": 60,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Maldives",
        "value": "MV",
        "icon": "mv",
        "iso_3": "MDV",
        "prefix": 960,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Mali",
        "value": "ML",
        "icon": "ml",
        "iso_3": "MLI",
        "prefix": 223,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Malta",
        "value": "MT",
        "icon": "mt",
        "iso_3": "MLT",
        "prefix": 356,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Marshall Islands",
        "value": "MH",
        "icon": "mh",
        "iso_3": "MHL",
        "prefix": 692,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Martinique",
        "value": "MQ",
        "icon": "mq",
        "iso_3": "MTQ",
        "prefix": 596,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Mauritania",
        "value": "MR",
        "icon": "mr",
        "iso_3": "MRT",
        "prefix": 222,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Mauritius",
        "value": "MU",
        "icon": "mu",
        "iso_3": "MUS",
        "prefix": 230,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Mayotte",
        "value": "YT",
        "icon": "yt",
        "iso_3": "MYT",
        "prefix": 262,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Mexico",
        "value": "MX",
        "icon": "mx",
        "iso_3": "MEX",
        "prefix": 52,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Micronesia, Federated States of",
        "value": "FM",
        "icon": "fm",
        "iso_3": "FSM",
        "prefix": 691,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Moldova, Republic of",
        "value": "MD",
        "icon": "md",
        "iso_3": "MDA",
        "prefix": 373,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Monaco",
        "value": "MC",
        "icon": "mc",
        "iso_3": "MCO",
        "prefix": 377,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "Mongolia",
        "value": "MN",
        "icon": "mn",
        "iso_3": "MNG",
        "prefix": 976,
        "region": "Asia",
        "sub_region": "Eastern Asia"
    },
    {
        "label": "Montenegro",
        "value": "ME",
        "icon": "me",
        "iso_3": "MNE",
        "prefix": 382,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Montserrat",
        "value": "MS",
        "icon": "ms",
        "iso_3": "MSR",
        "prefix": 1664,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Morocco",
        "value": "MA",
        "icon": "ma",
        "iso_3": "MAR",
        "prefix": 212,
        "region": "Africa",
        "sub_region": "Northern Africa"
    },
    {
        "label": "Mozambique",
        "value": "MZ",
        "icon": "mz",
        "iso_3": "MOZ",
        "prefix": 258,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Myanmar",
        "value": "MM",
        "icon": "mm",
        "iso_3": "MMR",
        "prefix": 95,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Namibia",
        "value": "NA",
        "icon": "na",
        "iso_3": "NAM",
        "prefix": 264,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Nauru",
        "value": "NR",
        "icon": "nr",
        "iso_3": "NRU",
        "prefix": 674,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Nepal",
        "value": "NP",
        "icon": "np",
        "iso_3": "NPL",
        "prefix": 977,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Netherlands, Kingdom of the",
        "value": "NL",
        "icon": "nl",
        "iso_3": "NLD",
        "prefix": 31,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "New Caledonia",
        "value": "NC",
        "icon": "nc",
        "iso_3": "NCL",
        "prefix": 687,
        "region": "Oceania",
        "sub_region": "Melanesia"
    },
    {
        "label": "New Zealand",
        "value": "NZ",
        "icon": "nz",
        "iso_3": "NZL",
        "prefix": 64,
        "region": "Oceania",
        "sub_region": "Australia and New Zealand"
    },
    {
        "label": "Nicaragua",
        "value": "NI",
        "icon": "ni",
        "iso_3": "NIC",
        "prefix": 505,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Niger",
        "value": "NE",
        "icon": "ne",
        "iso_3": "NER",
        "prefix": 227,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Nigeria",
        "value": "NG",
        "icon": "ng",
        "iso_3": "NGA",
        "prefix": 234,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Niue",
        "value": "NU",
        "icon": "nu",
        "iso_3": "NIU",
        "prefix": 683,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Norfolk Island",
        "value": "NF",
        "icon": "nf",
        "iso_3": "NFK",
        "prefix": 672,
        "region": "Oceania",
        "sub_region": "Australia and New Zealand"
    },
    {
        "label": "North Macedonia",
        "value": "MK",
        "icon": "mk",
        "iso_3": "MKD",
        "prefix": 389,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Northern Mariana Islands",
        "value": "MP",
        "icon": "mp",
        "iso_3": "MNP",
        "prefix": 1670,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Norway",
        "value": "NO",
        "icon": "no",
        "iso_3": "NOR",
        "prefix": 47,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Oman",
        "value": "OM",
        "icon": "om",
        "iso_3": "OMN",
        "prefix": 968,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Pakistan",
        "value": "PK",
        "icon": "pl",
        "iso_3": "PAK",
        "prefix": 92,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Palau",
        "value": "PW",
        "icon": "pw",
        "iso_3": "PLW",
        "prefix": 680,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Palestine, State of",
        "value": "PS",
        "icon": "ps",
        "iso_3": "PSE",
        "prefix": 970,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Panama",
        "value": "PA",
        "icon": "pa",
        "iso_3": "PAN",
        "prefix": 507,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Papua New Guinea",
        "value": "PG",
        "icon": "pg",
        "iso_3": "PNG",
        "prefix": 675,
        "region": "Oceania",
        "sub_region": "Melanesia"
    },
    {
        "label": "Paraguay",
        "value": "PY",
        "icon": "py",
        "iso_3": "PRY",
        "prefix": 595,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Peru",
        "value": "PE",
        "icon": "pe",
        "iso_3": "PER",
        "prefix": 51,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Philippines",
        "value": "PH",
        "icon": "ph",
        "iso_3": "PHL",
        "prefix": 63,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Pitcairn",
        "value": "PN",
        "icon": "pn",
        "iso_3": "PCN",
        "prefix": 64,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Poland",
        "value": "PL",
        "icon": "pl",
        "iso_3": "POL",
        "prefix": 48,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Portugal",
        "value": "PT",
        "icon": "pt",
        "iso_3": "PRT",
        "prefix": 351,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Puerto Rico",
        "value": "PR",
        "icon": "pr",
        "iso_3": "PRI",
        "prefix": 1787,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Qatar",
        "value": "QA",
        "icon": "qa",
        "iso_3": "QAT",
        "prefix": 974,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Réunion",
        "value": "RE",
        "icon": "re",
        "iso_3": "REU",
        "prefix": 262,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Romania",
        "value": "RO",
        "icon": "ro",
        "iso_3": "ROU",
        "prefix": 40,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Russian Federation",
        "value": "RU",
        "icon": "ru",
        "iso_3": "RUS",
        "prefix": 7,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Rwanda",
        "value": "RW",
        "icon": "rw",
        "iso_3": "RWA",
        "prefix": 250,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Saint Barthélemy",
        "value": "BL",
        "icon": "bl",
        "iso_3": "BLM",
        "prefix": 590,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Saint Helena, Ascension and Tristan da Cunha",
        "value": "SH",
        "icon": "sh",
        "iso_3": "SHN",
        "prefix": 247,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Saint Kitts and Nevis",
        "value": "KN",
        "icon": "kn",
        "iso_3": "KNA",
        "prefix": 1869,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Saint Lucia",
        "value": "LC",
        "icon": "lc",
        "iso_3": "LCA",
        "prefix": 1758,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Saint Martin (French part)",
        "value": "MF",
        "icon": "mf",
        "iso_3": "MAF",
        "prefix": 590,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Saint Pierre and Miquelon",
        "value": "PM",
        "icon": "pm",
        "iso_3": "SPM",
        "prefix": 508,
        "region": "Americas",
        "sub_region": "Northern America"
    },
    {
        "label": "Saint Vincent and the Grenadines",
        "value": "VC",
        "icon": "vc",
        "iso_3": "VCT",
        "prefix": 1784,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Samoa",
        "value": "WS",
        "icon": "ws",
        "iso_3": "WSM",
        "prefix": 685,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "San Marino",
        "value": "SM",
        "icon": "sm",
        "iso_3": "SMR",
        "prefix": 378,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Sao Tome and Principe",
        "value": "ST",
        "icon": "st",
        "iso_3": "STP",
        "prefix": 239,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Saudi Arabia",
        "value": "SA",
        "icon": "sa",
        "iso_3": "SAU",
        "prefix": 966,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Senegal",
        "value": "SN",
        "icon": "sn",
        "iso_3": "SEN",
        "prefix": 221,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Serbia",
        "value": "RS",
        "icon": "rs",
        "iso_3": "SRB",
        "prefix": 381,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Seychelles",
        "value": "SC",
        "icon": "sc",
        "iso_3": "SYC",
        "prefix": 248,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Sierra Leone",
        "value": "SL",
        "icon": "sl",
        "iso_3": "SLE",
        "prefix": 232,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Singapore",
        "value": "SG",
        "icon": "sg",
        "iso_3": "SGP",
        "prefix": 65,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Sint Maarten (Dutch part)",
        "value": "SX",
        "icon": "sx",
        "iso_3": "SXM",
        "prefix": 1721,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Slovakia",
        "value": "SK",
        "icon": "sk",
        "iso_3": "SVK",
        "prefix": 421,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "Slovenia",
        "value": "SI",
        "icon": "si",
        "iso_3": "SVN",
        "prefix": 386,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Solomon Islands",
        "value": "SB",
        "icon": "sb",
        "iso_3": "SLB",
        "prefix": 677,
        "region": "Oceania",
        "sub_region": "Melanesia"
    },
    {
        "label": "Somalia",
        "value": "SO",
        "icon": "so",
        "iso_3": "SOM",
        "prefix": 252,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "South Africa",
        "value": "ZA",
        "icon": "za",
        "iso_3": "ZAF",
        "prefix": 27,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "South Georgia and the South Sandwich Islands",
        "value": "GS",
        "icon": "gs",
        "iso_3": "SGS",
        "prefix": 500,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "South Sudan",
        "value": "SS",
        "icon": "ss",
        "iso_3": "SSD",
        "prefix": 211,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Spain",
        "value": "ES",
        "icon": "es",
        "iso_3": "ESP",
        "prefix": 34,
        "region": "Europe",
        "sub_region": "Southern Europe"
    },
    {
        "label": "Sri Lanka",
        "value": "LK",
        "icon": "lk",
        "iso_3": "LKA",
        "prefix": 94,
        "region": "Asia",
        "sub_region": "Southern Asia"
    },
    {
        "label": "Sudan",
        "value": "SD",
        "icon": "sd",
        "iso_3": "SDN",
        "prefix": 249,
        "region": "Africa",
        "sub_region": "Northern Africa"
    },
    {
        "label": "Suriname",
        "value": "SR",
        "icon": "sr",
        "iso_3": "SUR",
        "prefix": 597,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Svalbard and Jan Mayen",
        "value": "SJ",
        "icon": "sj",
        "iso_3": "SJM",
        "prefix": 47,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Sweden",
        "value": "SE",
        "icon": "se",
        "iso_3": "SWE",
        "prefix": 46,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "Switzerland",
        "value": "CH",
        "icon": "ch",
        "iso_3": "CHE",
        "prefix": 41,
        "region": "Europe",
        "sub_region": "Western Europe"
    },
    {
        "label": "Syrian Arab Republic",
        "value": "SY",
        "icon": "sy",
        "iso_3": "SYR",
        "prefix": 963,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Taiwan, Province of China",
        "value": "TW",
        "icon": "tw",
        "iso_3": "TWN",
        "prefix": 886,
        "region": "",
        "sub_region": ""
    },
    {
        "label": "Tajikistan",
        "value": "TJ",
        "icon": "tj",
        "iso_3": "TJK",
        "prefix": 992,
        "region": "Asia",
        "sub_region": "Central Asia"
    },
    {
        "label": "Tanzania, United Republic of",
        "value": "TZ",
        "icon": "tz",
        "iso_3": "TZA",
        "prefix": 255,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Thailand",
        "value": "TH",
        "icon": "th",
        "iso_3": "THA",
        "prefix": 66,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Timor-Leste",
        "value": "TL",
        "icon": "tl",
        "iso_3": "TLS",
        "prefix": 670,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Togo",
        "value": "TG",
        "icon": "tg",
        "iso_3": "TGO",
        "prefix": 288,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Tokelau",
        "value": "TK",
        "icon": "tk",
        "iso_3": "TKL",
        "prefix": 690,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Tonga",
        "value": "TO",
        "icon": "to",
        "iso_3": "TON",
        "prefix": 676,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Trinidad and Tobago",
        "value": "TT",
        "icon": "tt",
        "iso_3": "TTO",
        "prefix": 1868,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Tunisia",
        "value": "TN",
        "icon": "tn",
        "iso_3": "TUN",
        "prefix": 216,
        "region": "Africa",
        "sub_region": "Northern Africa"
    },
    {
        "label": "Türkiye",
        "value": "TR",
        "icon": "tr",
        "iso_3": "TUR",
        "prefix": 90,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Turkmenistan",
        "value": "TM",
        "icon": "tm",
        "iso_3": "TKM",
        "prefix": 993,
        "region": "Asia",
        "sub_region": "Central Asia"
    },
    {
        "label": "Turks and Caicos Islands",
        "value": "TC",
        "icon": "tc",
        "iso_3": "TCA",
        "prefix": 1649,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Tuvalu",
        "value": "TV",
        "icon": "tv",
        "iso_3": "TUV",
        "prefix": 688,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Uganda",
        "value": "UG",
        "icon": "ug",
        "iso_3": "UGA",
        "prefix": 256,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Ukraine",
        "value": "UA",
        "icon": "ua",
        "iso_3": "UKR",
        "prefix": 380,
        "region": "Europe",
        "sub_region": "Eastern Europe"
    },
    {
        "label": "United Arab Emirates",
        "value": "AE",
        "icon": "ae",
        "iso_3": "ARE",
        "prefix": 971,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "United Kingdom of Great Britain and Northern Ireland",
        "value": "GB",
        "icon": "gb",
        "iso_3": "GBR",
        "prefix": 44,
        "region": "Europe",
        "sub_region": "Northern Europe"
    },
    {
        "label": "United States of America",
        "value": "US",
        "icon": "us",
        "iso_3": "USA",
        "prefix": 1,
        "region": "Americas",
        "sub_region": "Northern America"
    },
    {
        "label": "United States Minor Outlying Islands",
        "value": "UM",
        "icon": "um",
        "iso_3": "UMI",
        "prefix": 1340,
        "region": "Oceania",
        "sub_region": "Micronesia"
    },
    {
        "label": "Uruguay",
        "value": "UY",
        "icon": "uy",
        "iso_3": "URY",
        "prefix": 598,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Uzbekistan",
        "value": "UZ",
        "icon": "uz",
        "iso_3": "UZB",
        "prefix": 998,
        "region": "Asia",
        "sub_region": "Central Asia"
    },
    {
        "label": "Vanuatu",
        "value": "VU",
        "icon": "vu",
        "iso_3": "VUT",
        "prefix": 678,
        "region": "Oceania",
        "sub_region": "Melanesia"
    },
    {
        "label": "Venezuela, Bolivarian Republic of",
        "value": "VE",
        "icon": "ve",
        "iso_3": "VEN",
        "prefix": 58,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Viet Nam",
        "value": "VN",
        "icon": "vn",
        "iso_3": "VNM",
        "prefix": 84,
        "region": "Asia",
        "sub_region": "South-eastern Asia"
    },
    {
        "label": "Virgin Islands (British)",
        "value": "VG",
        "icon": "vg",
        "iso_3": "VGB",
        "prefix": 284,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Virgin Islands (U.S.)",
        "value": "VI",
        "icon": "vi",
        "iso_3": "VIR",
        "prefix": 1340,
        "region": "Americas",
        "sub_region": "Latin America and the Caribbean"
    },
    {
        "label": "Wallis and Futuna",
        "value": "WF",
        "icon": "wf",
        "iso_3": "WLF",
        "prefix": 681,
        "region": "Oceania",
        "sub_region": "Polynesia"
    },
    {
        "label": "Western Sahara",
        "value": "EH",
        "icon": "eh",
        "iso_3": "ESH",
        "prefix": 212,
        "region": "Africa",
        "sub_region": "Northern Africa"
    },
    {
        "label": "Yemen",
        "value": "YE",
        "icon": "ye",
        "iso_3": "YEM",
        "prefix": 967,
        "region": "Asia",
        "sub_region": "Western Asia"
    },
    {
        "label": "Zambia",
        "value": "ZM",
        "icon": "zm",
        "iso_3": "ZMB",
        "prefix": 260,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    },
    {
        "label": "Zimbabwe",
        "value": "ZW",
        "icon": "zw",
        "iso_3": "ZWE",
        "prefix": 263,
        "region": "Africa",
        "sub_region": "Sub-Saharan Africa"
    }
]

export const countriesPrefixes = [
    {
        "label": 93,
        "icon": "af",
        "value": 93
    },
    {
        "label": 358,
        "icon": "ax",
        "value": 358
    },
    {
        "label": 355,
        "icon": "al",
        "value": 355
    },
    {
        "label": 213,
        "icon": "dz",
        "value": 213
    },
    {
        "label": 1684,
        "icon": "as",
        "value": 1684
    },
    {
        "label": 376,
        "icon": "ad",
        "value": 376
    },
    {
        "label": 244,
        "icon": "ao",
        "value": 244
    },
    {
        "label": 1264,
        "icon": "ai",
        "value": 1264
    },
    // {
    //     "label": 672,
    //     "icon": "aq",
    //     "value": 672
    // },
    {
        "label": 1268,
        "icon": "ag",
        "value": 1268
    },
    {
        "label": 54,
        "icon": "ar",
        "value": 54
    },
    {
        "label": 374,
        "icon": "am",
        "value": 374
    },
    {
        "label": 297,
        "icon": "aw",
        "value": 297
    },
    {
        "label": 61,
        "icon": "au",
        "value": 61
    },
    {
        "label": 43,
        "icon": "at",
        "value": 43
    },
    {
        "label": 994,
        "icon": "az",
        "value": 994
    },
    {
        "label": 1242,
        "icon": "bs",
        "value": 1242
    },
    {
        "label": 973,
        "icon": "bh",
        "value": 973
    },
    {
        "label": 880,
        "icon": "bd",
        "value": 880
    },
    {
        "label": 1246,
        "icon": "bb",
        "value": 1246
    },
    {
        "label": 375,
        "icon": "by",
        "value": 375
    },
    {
        "label": 32,
        "icon": "be",
        "value": 32
    },
    {
        "label": 501,
        "icon": "bz",
        "value": 501
    },
    {
        "label": 229,
        "icon": "bj",
        "value": 229
    },
    {
        "label": 1441,
        "icon": "bm",
        "value": 1441
    },
    {
        "label": 975,
        "icon": "bt",
        "value": 975
    },
    {
        "label": 591,
        "icon": "bo",
        "value": 591
    },
    // {
    //     "label": 599,
    //     "icon": "bq",
    //     "value": 599
    // },
    {
        "label": 387,
        "icon": "ba",
        "value": 387
    },
    {
        "label": 267,
        "icon": "bw",
        "value": 267
    },
    {
        "label": 47,
        "icon": "bv",
        "value": 47
    },
    {
        "label": 55,
        "icon": "br",
        "value": 55
    },
    {
        "label": 1284,
        "icon": "io",
        "value": 1284
    },
    {
        "label": 673,
        "icon": "bn",
        "value": 673
    },
    {
        "label": 359,
        "icon": "bg",
        "value": 359
    },
    {
        "label": 226,
        "icon": "bf",
        "value": 226
    },
    {
        "label": 257,
        "icon": "bi",
        "value": 257
    },
    {
        "label": 238,
        "icon": "cv",
        "value": 238
    },
    {
        "label": 855,
        "icon": "kh",
        "value": 855
    },
    {
        "label": 237,
        "icon": "cm",
        "value": 237
    },
    {
        "label": 1,
        "icon": "ca",
        "value": 1
    },
    {
        "label": 1345,
        "icon": "ky",
        "value": 1345
    },
    {
        "label": 236,
        "icon": "cf",
        "value": 236
    },
    {
        "label": 235,
        "icon": "td",
        "value": 235
    },
    {
        "label": 56,
        "icon": "cl",
        "value": 56
    },
    {
        "label": 86,
        "icon": "cn",
        "value": 86
    },
    {
        "label": 61,
        "icon": "cx",
        "value": 61
    },
    {
        "label": 61,
        "icon": "cc",
        "value": 61
    },
    {
        "label": 57,
        "icon": "co",
        "value": 57
    },
    {
        "label": 269,
        "icon": "km",
        "value": 269
    },
    {
        "label": 242,
        "icon": "cg",
        "value": 242
    },
    {
        "label": 242,
        "icon": "cd",
        "value": 242
    },
    {
        "label": 682,
        "icon": "ck",
        "value": 682
    },
    {
        "label": 506,
        "icon": "cr",
        "value": 506
    },
    {
        "label": 225,
        "icon": "ci",
        "value": 225
    },
    {
        "label": 385,
        "icon": "hr",
        "value": 385
    },
    {
        "label": 53,
        "icon": "cu",
        "value": 53
    },
    {
        "label": 599,
        "icon": "cw",
        "value": 599
    },
    {
        "label": 357,
        "icon": "cy",
        "value": 357
    },
    {
        "label": 420,
        "icon": "cz",
        "value": 420
    },
    {
        "label": 45,
        "icon": "dk",
        "value": 45
    },
    {
        "label": 253,
        "icon": "dj",
        "value": 253
    },
    {
        "label": 1767,
        "icon": "dm",
        "value": 1767
    },
    {
        "label": 1809,
        "icon": "do",
        "value": 1809
    },
    {
        "label": 593,
        "icon": "ec",
        "value": 593
    },
    {
        "label": 20,
        "icon": "eg",
        "value": 20
    },
    {
        "label": 503,
        "icon": "sv",
        "value": 503
    },
    {
        "label": 240,
        "icon": "gq",
        "value": 240
    },
    {
        "label": 291,
        "icon": "er",
        "value": 291
    },
    {
        "label": 372,
        "icon": "ee",
        "value": 372
    },
    {
        "label": 268,
        "icon": "sz",
        "value": 268
    },
    {
        "label": 251,
        "icon": "et",
        "value": 251
    },
    {
        "label": 500,
        "icon": "fk",
        "value": 500
    },
    {
        "label": 298,
        "icon": "fo",
        "value": 298
    },
    {
        "label": 679,
        "icon": "fj",
        "value": 679
    },
    {
        "label": 358,
        "icon": "fi",
        "value": 358
    },
    {
        "label": 33,
        "icon": "fr",
        "value": 33
    },
    {
        "label": 594,
        "icon": "gf",
        "value": 594
    },
    {
        "label": 689,
        "icon": "pf",
        "value": 689
    },
    // {
    //     "label": 262,
    //     "icon": "tf",
    //     "value": 262
    // },
    {
        "label": 241,
        "icon": "ga",
        "value": 241
    },
    {
        "label": 220,
        "icon": "gm",
        "value": 220
    },
    {
        "label": 995,
        "icon": "ge",
        "value": 995
    },
    {
        "label": 49,
        "icon": "de",
        "value": 49
    },
    {
        "label": 233,
        "icon": "gh",
        "value": 233
    },
    {
        "label": 350,
        "icon": "gi",
        "value": 350
    },
    {
        "label": 30,
        "icon": "gr",
        "value": 30
    },
    {
        "label": 299,
        "icon": "gl",
        "value": 299
    },
    {
        "label": 1473,
        "icon": "gd",
        "value": 1473
    },
    {
        "label": 590,
        "icon": "gp",
        "value": 590
    },
    {
        "label": 1671,
        "icon": "gu",
        "value": 1671
    },
    {
        "label": 502,
        "icon": "gt",
        "value": 502
    },
    {
        "label": 44,
        "icon": "gg",
        "value": 44
    },
    {
        "label": 224,
        "icon": "gn",
        "value": 224
    },
    {
        "label": 245,
        "icon": "gw",
        "value": 245
    },
    {
        "label": 592,
        "icon": "gy",
        "value": 592
    },
    {
        "label": 509,
        "icon": "ht",
        "value": 509
    },
    {
        "label": 334,
        "icon": "hm",
        "value": 334
    },
    {
        "label": 379,
        "icon": "va",
        "value": 379
    },
    {
        "label": 504,
        "icon": "hn",
        "value": 504
    },
    {
        "label": 852,
        "icon": "hk",
        "value": 852
    },
    {
        "label": 36,
        "icon": "hu",
        "value": 36
    },
    {
        "label": 354,
        "icon": "is",
        "value": 354
    },
    {
        "label": 91,
        "icon": "in",
        "value": 91
    },
    {
        "label": 62,
        "icon": "id",
        "value": 62
    },
    {
        "label": 98,
        "icon": "ir",
        "value": 98
    },
    {
        "label": 964,
        "icon": "iq",
        "value": 964
    },
    {
        "label": 353,
        "icon": "ie",
        "value": 353
    },
    {
        "label": 44,
        "icon": "im",
        "value": 44
    },
    {
        "label": 972,
        "icon": "il",
        "value": 972
    },
    {
        "label": 39,
        "icon": "it",
        "value": 39
    },
    {
        "label": 1876,
        "icon": "jm",
        "value": 1876
    },
    {
        "label": 81,
        "icon": "jp",
        "value": 81
    },
    {
        "label": 44,
        "icon": "je",
        "value": 44
    },
    {
        "label": 862,
        "icon": "jo",
        "value": 862
    },
    {
        "label": 7,
        "icon": "kz",
        "value": 7
    },
    {
        "label": 254,
        "icon": "ke",
        "value": 254
    },
    {
        "label": 686,
        "icon": "ki",
        "value": 686
    },
    {
        "label": 850,
        "icon": "kp",
        "value": 850
    },
    {
        "label": 82,
        "icon": "kr",
        "value": 82
    },
    {
        "label": 965,
        "icon": "kw",
        "value": 965
    },
    {
        "label": 996,
        "icon": "kg",
        "value": 996
    },
    {
        "label": 856,
        "icon": "la",
        "value": 856
    },
    {
        "label": 371,
        "icon": "lv",
        "value": 371
    },
    {
        "label": 961,
        "icon": "lb",
        "value": 961
    },
    {
        "label": 266,
        "icon": "ls",
        "value": 266
    },
    {
        "label": 231,
        "icon": "lr",
        "value": 231
    },
    {
        "label": 218,
        "icon": "ly",
        "value": 218
    },
    {
        "label": 423,
        "icon": "li",
        "value": 423
    },
    {
        "label": 370,
        "icon": "lt",
        "value": 370
    },
    {
        "label": 352,
        "icon": "lu",
        "value": 352
    },
    {
        "label": 853,
        "icon": "mo",
        "value": 853
    },
    {
        "label": 261,
        "icon": "mg",
        "value": 261
    },
    {
        "label": 265,
        "icon": "mw",
        "value": 265
    },
    {
        "label": 60,
        "icon": "my",
        "value": 60
    },
    {
        "label": 960,
        "icon": "mv",
        "value": 960
    },
    {
        "label": 223,
        "icon": "ml",
        "value": 223
    },
    {
        "label": 356,
        "icon": "mt",
        "value": 356
    },
    {
        "label": 692,
        "icon": "mh",
        "value": 692
    },
    {
        "label": 596,
        "icon": "mq",
        "value": 596
    },
    {
        "label": 222,
        "icon": "mr",
        "value": 222
    },
    {
        "label": 230,
        "icon": "mu",
        "value": 230
    },
    {
        "label": 262,
        "icon": "yt",
        "value": 262
    },
    {
        "label": 52,
        "icon": "mx",
        "value": 52
    },
    {
        "label": 691,
        "icon": "fm",
        "value": 691
    },
    {
        "label": 373,
        "icon": "md",
        "value": 373
    },
    {
        "label": 377,
        "icon": "mc",
        "value": 377
    },
    {
        "label": 976,
        "icon": "mn",
        "value": 976
    },
    {
        "label": 382,
        "icon": "me",
        "value": 382
    },
    {
        "label": 1664,
        "icon": "ms",
        "value": 1664
    },
    {
        "label": 212,
        "icon": "ma",
        "value": 212
    },
    {
        "label": 258,
        "icon": "mz",
        "value": 258
    },
    {
        "label": 95,
        "icon": "mm",
        "value": 95
    },
    {
        "label": 264,
        "icon": "na",
        "value": 264
    },
    {
        "label": 674,
        "icon": "nr",
        "value": 674
    },
    {
        "label": 977,
        "icon": "np",
        "value": 977
    },
    {
        "label": 31,
        "icon": "nl",
        "value": 31
    },
    {
        "label": 687,
        "icon": "nc",
        "value": 687
    },
    {
        "label": 64,
        "icon": "nz",
        "value": 64
    },
    {
        "label": 505,
        "icon": "ni",
        "value": 505
    },
    {
        "label": 227,
        "icon": "ne",
        "value": 227
    },
    {
        "label": 234,
        "icon": "ng",
        "value": 234
    },
    {
        "label": 683,
        "icon": "nu",
        "value": 683
    },
    {
        "label": 672,
        "icon": "nf",
        "value": 672
    },
    {
        "label": 389,
        "icon": "mk",
        "value": 389
    },
    {
        "label": 1670,
        "icon": "mp",
        "value": 1670
    },
    {
        "label": 47,
        "icon": "no",
        "value": 47
    },
    {
        "label": 968,
        "icon": "om",
        "value": 968
    },
    {
        "label": 92,
        "icon": "pl",
        "value": 92
    },
    {
        "label": 680,
        "icon": "pw",
        "value": 680
    },
    {
        "label": 970,
        "icon": "ps",
        "value": 970
    },
    {
        "label": 507,
        "icon": "pa",
        "value": 507
    },
    {
        "label": 675,
        "icon": "pg",
        "value": 675
    },
    {
        "label": 595,
        "icon": "py",
        "value": 595
    },
    {
        "label": 51,
        "icon": "pe",
        "value": 51
    },
    {
        "label": 63,
        "icon": "ph",
        "value": 63
    },
    {
        "label": 64,
        "icon": "pn",
        "value": 64
    },
    {
        "label": 48,
        "icon": "pl",
        "value": 48
    },
    {
        "label": 351,
        "icon": "pt",
        "value": 351
    },
    {
        "label": 1787,
        "icon": "pr",
        "value": 1787
    },
    {
        "label": 974,
        "icon": "qa",
        "value": 974
    },
    {
        "label": 262,
        "icon": "re",
        "value": 262
    },
    {
        "label": 40,
        "icon": "ro",
        "value": 40
    },
    {
        "label": 7,
        "icon": "ru",
        "value": 7
    },
    {
        "label": 250,
        "icon": "rw",
        "value": 250
    },
    {
        "label": 590,
        "icon": "bl",
        "value": 590
    },
    {
        "label": 247,
        "icon": "sh",
        "value": 247
    },
    {
        "label": 1869,
        "icon": "kn",
        "value": 1869
    },
    {
        "label": 1758,
        "icon": "lc",
        "value": 1758
    },
    {
        "label": 590,
        "icon": "mf",
        "value": 590
    },
    {
        "label": 508,
        "icon": "pm",
        "value": 508
    },
    {
        "label": 1784,
        "icon": "vc",
        "value": 1784
    },
    {
        "label": 685,
        "icon": "ws",
        "value": 685
    },
    {
        "label": 378,
        "icon": "sm",
        "value": 378
    },
    {
        "label": 239,
        "icon": "st",
        "value": 239
    },
    {
        "label": 966,
        "icon": "sa",
        "value": 966
    },
    {
        "label": 221,
        "icon": "sn",
        "value": 221
    },
    {
        "label": 381,
        "icon": "rs",
        "value": 381
    },
    {
        "label": 248,
        "icon": "sc",
        "value": 248
    },
    {
        "label": 232,
        "icon": "sl",
        "value": 232
    },
    {
        "label": 65,
        "icon": "sg",
        "value": 65
    },
    {
        "label": 1721,
        "icon": "sx",
        "value": 1721
    },
    {
        "label": 421,
        "icon": "sk",
        "value": 421
    },
    {
        "label": 386,
        "icon": "si",
        "value": 386
    },
    {
        "label": 677,
        "icon": "sb",
        "value": 677
    },
    {
        "label": 252,
        "icon": "so",
        "value": 252
    },
    {
        "label": 27,
        "icon": "za",
        "value": 27
    },
    {
        "label": 500,
        "icon": "gs",
        "value": 500
    },
    {
        "label": 211,
        "icon": "ss",
        "value": 211
    },
    {
        "label": 34,
        "icon": "es",
        "value": 34
    },
    {
        "label": 94,
        "icon": "lk",
        "value": 94
    },
    {
        "label": 249,
        "icon": "sd",
        "value": 249
    },
    {
        "label": 597,
        "icon": "sr",
        "value": 597
    },
    {
        "label": 47,
        "icon": "sj",
        "value": 47
    },
    {
        "label": 46,
        "icon": "se",
        "value": 46
    },
    {
        "label": 41,
        "icon": "ch",
        "value": 41
    },
    {
        "label": 963,
        "icon": "sy",
        "value": 963
    },
    {
        "label": 886,
        "icon": "tw",
        "value": 886
    },
    {
        "label": 992,
        "icon": "tj",
        "value": 992
    },
    {
        "label": 255,
        "icon": "tz",
        "value": 255
    },
    {
        "label": 66,
        "icon": "th",
        "value": 66
    },
    {
        "label": 670,
        "icon": "tl",
        "value": 670
    },
    {
        "label": 288,
        "icon": "tg",
        "value": 288
    },
    {
        "label": 690,
        "icon": "tk",
        "value": 690
    },
    {
        "label": 676,
        "icon": "to",
        "value": 676
    },
    {
        "label": 1868,
        "icon": "tt",
        "value": 1868
    },
    {
        "label": 216,
        "icon": "tn",
        "value": 216
    },
    {
        "label": 90,
        "icon": "tr",
        "value": 90
    },
    {
        "label": 993,
        "icon": "tm",
        "value": 993
    },
    {
        "label": 1649,
        "icon": "tc",
        "value": 1649
    },
    {
        "label": 688,
        "icon": "tv",
        "value": 688
    },
    {
        "label": 256,
        "icon": "ug",
        "value": 256
    },
    {
        "label": 380,
        "icon": "ua",
        "value": 380
    },
    {
        "label": 971,
        "icon": "ae",
        "value": 971
    },
    {
        "label": 44,
        "icon": "gb",
        "value": 44
    },
    {
        "label": 1,
        "icon": "us",
        "value": 1
    },
    {
        "label": 1340,
        "icon": "um",
        "value": 1340
    },
    {
        "label": 598,
        "icon": "uy",
        "value": 598
    },
    {
        "label": 998,
        "icon": "uz",
        "value": 998
    },
    {
        "label": 678,
        "icon": "vu",
        "value": 678
    },
    {
        "label": 58,
        "icon": "ve",
        "value": 58
    },
    {
        "label": 84,
        "icon": "vn",
        "value": 84
    },
    {
        "label": 284,
        "icon": "vg",
        "value": 284
    },
    {
        "label": 1340,
        "icon": "vi",
        "value": 1340
    },
    {
        "label": 681,
        "icon": "wf",
        "value": 681
    },
    {
        "label": 212,
        "icon": "eh",
        "value": 212
    },
    {
        "label": 967,
        "icon": "ye",
        "value": 967
    },
    {
        "label": 260,
        "icon": "zm",
        "value": 260
    },
    {
        "label": 263,
        "icon": "zw",
        "value": 263
    }
]
export const genders = ref([
    {
        value: 'F',
        label: 'Female',
        icon: 'female',
        class: 'female-btn'
    },
    {
        value: 'M',
        label: 'Male',
        icon: 'male',
        class: 'male-btn'
    }
])

export const campaign_types = ref([
    {
        value: 'private',
        label: 'Private',
        icon: 'private',
        description: 'Select the influencers you would like to collaborate with',
        disabled: false
    },
    {
        value: 'public',
        label: 'Public',
        icon: 'public',
        description: 'Publish your campaign to receive influencers applications',
        disabled: true
    }
])

export const campaign_objectives = ref([
    {
        value: 'traffic',
        label: 'Traffic',
        icon: 'traffic',
    },
    {
        value: 'brand-awareness',
        label: 'Brand Awareness',
        icon: 'brand-awareness',
    },
    {
        value: 'product-launch',
        label: 'Product launch',
        icon: 'product-launch',
    },
    {
        value: 'store-opening',
        label: 'Store opening',
        icon: 'store-opening',
    },
    {
        value: 'engagement',
        label: 'Engagement',
        icon: 'engagement',
    },
    {
        value: 'purchases-sales',
        label: 'Purchases/Sales',
        icon: 'purchases-sales',
    },
    {
        value: 'lead-generation',
        label: 'Lead generation',
        icon: 'lead-generation',
    },
    {
        value: 'customer-loyalty',
        label: 'Customer loyalty',
        icon: 'customer-loyalty',
    },
    {
        value: 'app-install',
        label: 'App install',
        icon: 'app-install',
    },
])


export const campaign_objectives_colored = ref([
    {
        value: 'traffic',
        label: 'Traffic',
        icon: 'traffic',
    },
    {
        value: 'brand-awareness',
        label: 'Brand Awareness',
        icon: 'brand-awareness',
    },
    {
        value: 'product-launch',
        label: 'Product launch',
        icon: 'product-launch',
    },
    {
        value: 'store-opening',
        label: 'Store opening',
        icon: 'store-opening',
    },
    {
        value: 'engagement',
        label: 'Engagement',
        icon: 'engagement',
    },
    {
        value: 'purchases-sales',
        label: 'Purchases/Sales',
        icon: 'purchases-sales',
    },
    {
        value: 'lead-generation',
        label: 'Lead generation',
        icon: 'lead-generation',
    },
    {
        value: 'customer-loyalty',
        label: 'Customer loyalty',
        icon: 'customer-loyalty',
    },
    {
        value: 'app-install',
        label: 'App install',
        icon: 'app-install',
    },
])

export const ages = [
    /* {
        value: '12-18',
        label: '12-18',
        icon: '12_18'
    }, */
    {
        value: '18-25',
        label: '18-25',
        icon: '18_25'
    },
    {
        value: '25-35',
        label: '25-35',
        icon: '25_35'
    },
    {
        value: '35-45',
        label: '35-45',
        icon: '35_45'
    },
    {
        value: '45plus',
        label: '45+',
        icon: '45plus'
    }
]

export const content_types_old = [
    {
        value: 'post-photo',
        label: 'Post photo',
        icon: 'post-photo'
    },
    {
        value: 'post-reel',
        label: 'Post reel',
        icon: 'post-reel'
    },
    {
        value: 'organic-story',
        label: 'Organic story',
        icon: 'organic-story'
    },
    {
        value: 'unboxing',
        label: 'Unboxing',
        icon: 'unboxing'
    },
    {
        value: 'event-coverage',
        label: 'Event coverage',
        icon: 'event-coverage'
    },
    {
        value: 'boutique-tour',
        label: 'Boutique tour',
        icon: 'boutique-tour'
    },
    {
        value: 'play-to-win',
        label: 'Play-to-win',
        icon: 'play-to-win'
    }
]

export const followersCount = [
    {
        value: '[0,50000]',
        label: 'Nano-Micro [0 - 50K] <span style="color: #dbc02d">$</span>',
        shortLabel: 'Nano-Micro [0 - 50K]',
        typeLabel: 'Nano-Micro',
        intervalLabel: '[0 - 50K]',
        intervalLabelNT: '0 - 50K',
    },
    {
        value: '[50000,300000]',
        label: 'Mid-Tier [50k - 300K] <span style="color: #dbc02d">$$</span>',
        shortLabel: 'Mid-Tier [50k - 300K]',
        typeLabel: 'Mid-Tier',
        intervalLabel: '[50k - 300K]',
        intervalLabelNT: '50k - 300K',

    },
    {
        value: '[300000,1000000]',
        label: 'Macro [300K - 1M] <span style="color: #dbc02d">$$$</span>',
        shortLabel: 'Macro [300K - 1M]',
        typeLabel: 'Macro',
        intervalLabel: '[300K - 1M]',
        intervalLabelNT: '300K - 1M',

    },
    {
        value: '[1000000,900000000]',
        label: 'Mega [1M+] <span style="color: #dbc02d">$$$$</span>',
        shortLabel: 'Mega [1M+]',
        typeLabel: 'Mega',
        intervalLabel: '[1M+]',
        intervalLabelNT: '1M+',

    },

]

export const followersCountForDropDown = [
    {
        value: [0, 50000],
        label: '0 - 50K',
    },
    {
        value: [50000, 300000],
        label: '50k - 300K',
    },
    {
        value: [300000, 1000000],
        label: '300K - 1M',
    },
    {
        value: [1000000, 900000000],
        label: '1M+',
    },
]

export const engagementRates = [
    {
        value: '[0.5,1]',
        label: '0% - 1%'
    },
    {
        value: '[1,3]',
        label: '1% - 3%'
    },
    {
        value: '[3,6]',
        label: '3% - 6%'
    },
    {
        value: '[6,10]',
        label: '6% - 10%'
    },
    {
        value: '[10,100000]',
        label: '10% +'
    }
]
export const engagementRatesForDropDown = [
    {
        value: [0.5, 1],
        label: '0% - 1%'
    },
    {
        value: [1, 3],
        label: '1% - 3%'
    },
    {
        value: [3, 6],
        label: '3% - 6%'
    },
    {
        value: [6, 10],
        label: '6% - 10%'
    },
    {
        value: [10, 100000],
        label: '10% +'
    }
]

export const paymentMethods = [
    {
        value: 'money',
        label: 'Money'
    },
    {
        value: 'service',
        label: 'Service'
    },
    {
        value: 'money/service',
        label: 'Money & Service'
    },
]

export const personReached = [
    {
        value: '[0,5000]',
        label: '0 - 5k'
    },
    {
        value: '[5000,10000]',
        label: '5K - 10k'
    },
    {
        value: '[10000,50000]',
        label: '10K - 50k'
    },
    {
        value: '[50000,100000]',
        label: '50K - 100k'
    },
    {
        value: '[100000,100000000]',
        label: '100K+'
    }
]

export const colors = ref([
    {
        value: '#6B5DD5',
        label: 'Purple',
    },
    {
        value: '#03224c',
        label: 'Blue',
    },
    {
        value: '#D948CA',
        label: 'Pink',
    },
    {
        value: '#008009',
        label: 'Green',
    },
    {
        value: '#C20417',
        label: 'Red',
    },
    {
        value: '#DB4900',
        label: 'Orange',
    }
])

export const hours = ref([
    {
        value: '00:00:00',
        label: '00:00 AM',
    },
    {
        value: '00:30:00',
        label: '00:30 AM',
    },
    {
        value: '01:00:00',
        label: '01:00 AM',
    },
    {
        value: '01:30:00',
        label: '01:30 AM',
    },
    {
        value: '02:00:00',
        label: '02:00 AM',
    },
    {
        value: '02:30:00',
        label: '02:30 AM',
    },
    {
        value: '03:00:00',
        label: '03:00 AM',
    },
    {
        value: '03:30:00',
        label: '03:30 AM',
    },
    {
        value: '04:00:00',
        label: '04:00 AM',
    },
    {
        value: '04:30:00',
        label: '04:30 AM',
    },
    {
        value: '05:00:00',
        label: '05:00 AM',
    },
    {
        value: '05:30:00',
        label: '05:30 AM',
    },
    {
        value: '06:00:00',
        label: '06:00 AM',
    },
    {
        value: '06:30:00',
        label: '06:30 AM',
    },
    {
        value: '07:00:00',
        label: '07:00 AM',
    },
    {
        value: '07:30:00',
        label: '07:30 AM',
    },
    {
        value: '08:00:00',
        label: '08:00 AM',
    },
    {
        value: '08:30:00',
        label: '08:30 AM',
    },
    {
        value: '09:00:00',
        label: '09:00 AM',
    },
    {
        value: '09:30:00',
        label: '09:30 AM',
    },
    {
        value: '10:00:00',
        label: '10:00 AM',
    },
    {
        value: '10:30:00',
        label: '10:30 AM',
    },
    {
        value: '11:00:00',
        label: '11:00 AM',
    },
    {
        value: '11:30:00',
        label: '11:30 AM',
    },
    {
        value: '12:00:00',
        label: '12:00 PM',
    },
    {
        value: '13:00:00',
        label: '01:00 PM',
    },
    {
        value: '13:30:00',
        label: '01:30 PM',
    },
    {
        value: '14:00:00',
        label: '02:00 PM',
    },
    {
        value: '14:30:00',
        label: '02:30 PM',
    },
    {
        value: '15:00:00',
        label: '03:00 PM',
    },
    {
        value: '15:30:00',
        label: '03:30 PM',
    },
    {
        value: '16:00:00',
        label: '04:00 PM',
    },
    {
        value: '16:30:00',
        label: '04:30 PM',
    },
    {
        value: '17:00:00',
        label: '05:00 PM',
    },
    {
        value: '17:30:00',
        label: '05:30 PM',
    },
    {
        value: '18:00:00',
        label: '06:00 PM',
    },
    {
        value: '18:30:00',
        label: '06:30 PM',
    },
    {
        value: '19:00:00',
        label: '07:00 PM',
    },
    {
        value: '19:30:00',
        label: '07:30 PM',
    },
    {
        value: '20:00:00',
        label: '08:00 PM',
    },
    {
        value: '20:30:00',
        label: '08:30 PM',
    },
    {
        value: '21:00:00',
        label: '09:00 PM',
    },
    {
        value: '21:30:00',
        label: '09:30 PM',
    },
    {
        value: '22:00:00',
        label: '10:00 PM',
    },
    {
        value: '22:30:00',
        label: '10:30 PM',
    },
    {
        value: '23:00:00',
        label: '11:00 PM',
    },
    {
        value: '23:30:00',
        label: '11:30 PM',
    },
])


export const shortlist_influencer_infos = [
    {
        value: 'story-set',
        label: 'Prix 1 Vague de stories',
    },
    {
        value: 'story',
        label: 'Prix 1 Story',
    },
    {
        value: 'event-coverage',
        label: 'Prix 1 Couverture event en stories',
    },
    {
        value: 'unboxing',
        label: 'Prix 1 Unboxing stories',
    },
    {
        value: 'post-reel',
        label: 'Prix 1 Reel',
    },
    {
        value: 'passage-magasin',
        label: 'Prix Passage en magasin',
    },
    {
        value: 'fixed-price',
        label: 'Prix Forfaitaire',
    },
    {
        value: 'tiktok-video',
        label: 'Prix 1 Tiktok',
    },
]

export const content_types = [
    {
        value: 'story-set',
        label: 'Prix 1 Vague de stories',
        icon: 'story-set'
    },
    {
        value: 'story',
        label: 'Prix 1 Story',
        icon: 'story'
    },
    {
        value: 'event-coverage',
        label: 'Prix 1 Couverture event en stories',
        icon: 'event-coverage',
    },
    {
        value: 'unboxing',
        label: 'Prix 1 Unboxing stories',
        icon: 'unboxing',
    },
    {
        value: 'post-reel',
        label: 'Prix 1 Reel',
        icon: 'post-reel',
    },
    {
        value: 'passage-magasin',
        label: 'Passage en magasin',
        icon: 'passage-magasin',
    },
    {
        value: 'fixed-price',
        label: 'Prix Forfaitaire',
        icon: 'fixed-price',
    },
    {
        value: 'tiktok-video',
        label: 'Prix 1 Tiktok',
        icon: 'tiktok-video',
    },
]

export const delivrables = [
    {
        value: 'story-set',
        label: 'Vague de stories',
        icon: 'story-set'
    },
    {
        value: 'story',
        label: 'Une story',
        icon: 'story'
    },
    {
        value: 'event-coverage',
        label: 'Couverture event en stories',
        icon: 'event-coverage',
    },
    {
        value: 'unboxing',
        label: 'Unboxing stories',
        icon: 'unboxing',
    },
    {
        value: 'post-reel',
        label: 'Reel',
        icon: 'post-reel',
    },
    {
        value: 'passage-magasin',
        label: 'Passage en magasin',
        icon: 'passage-magasin',
    },
    {
        value: 'fixed-price',
        label: 'Prix Forfaitaire',
        icon: 'fixed-price',
    },
    {
        value: 'tiktok-video',
        label: 'Tiktok Video',
        icon: 'tiktok-video',
    },
]

export const content_media_kpis = [
    {
        value: 'avg-story-views',
        label: 'Average story views',
        icon: 'avg-story-views'
    },
    {
        value: 'link-clicks',
        label: 'Total Link clicks ',
        icon: 'link-clicks',
    },
    {
        value: 'tag-clicks',
        label: 'Total Tag clicks',
        icon: 'tag-clicks',
    },
    {
        value: 'views',
        label: 'Total Views',
        icon: 'views',
    },
    {
        value: 'likes',
        label: 'Total Likes',
        icon: 'likes',
    },
    {
        value: 'comments',
        label: 'Total Comments',
        icon: 'comments',
    },
    {
        value: 'shares',
        label: 'Total Shares',
        icon: 'shares',
    },
    {
        value: 'saves',
        label: 'Total Saves',
        icon: 'saves',
    },
    {
        value: 'total-interactions',
        label: 'Total interactions',
        icon: 'total-interactions',
    },
    {
        value: 'manually-added-kpi',
        label: 'Another kpi',
        icon: 'manually-added-kpi',
    },
]


export const advertiser_platform_primary_goals = [
    {
        value: 'Manage and organize influencer marketing campaigns',
        label: 'Manage and organize influencer marketing campaigns',
    },
    {
        value: 'Discover new influencers',
        label: 'Discover new influencers',
    },
    {
        value: 'Have advanced analytics on influencers',
        label: 'Have advanced analytics on influencers',
    },
    {
        value: 'Track and analyze influencer performance',
        label: 'Track and analyze influencer performance',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]


export const advertiser_primary_campaign_objectives = [
    {
        value: 'Building Brand Awareness',
        label: 'Building Brand Awareness',

    },
    {
        value: 'Event Promotion',
        label: 'Event Promotion',
    },
    {
        value: 'Driving Traffic',
        label: 'Driving Traffic',
    },
    {
        value: 'Launching a Product or Store',
        label: 'Launching a Product or Store',
    },
    {
        value: 'Boosting Engagement and Customer Loyalty',
        label: 'Boosting Engagement and Customer Loyalty',
    },
    {
        value: 'Generating Leads and Increasing Sales/Purchases',
        label: 'Generating Leads and Increasing Sales/Purchases',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]


export const advertiser_occupations = [
    {
        value: 'Marketing Manager',
        label: 'Marketing Manager',

    },
    {
        value: 'Account Manager',
        label: 'Account Manager',
    },
    {
        value: 'Social Media Manager',
        label: 'Social Media Manager',
    },
    {
        value: 'Brand Manager',
        label: 'Brand Manager',
    },
    {
        value: 'CEO/Founder',
        label: 'CEO/Founder',
    },
    {
        value: 'Trainee',
        label: 'Trainee',
    },
    {
        value: 'Student',
        label: 'Student',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]

export const company_sizes = [
    {
        value: '1-10 employees',
        label: '1-10 employees',

    },
    {
        value: '11-50 employees',
        label: '11-50 employees',
    },
    {
        value: '51-200 employees',
        label: '51-200 employees',
    },
    {
        value: '201-500 employees',
        label: '201-500 employees',
    },
    {
        value: '501-1000 employees',
        label: '501-1000 employees',
    },
    {
        value: '1001+ employees',
        label: '1001+ employees',
    },
]

export const advertiser_influencer_marketing_experience = [
    {
        value: 'Beginner',
        label: 'Beginner',

    },
    {
        value: 'Led Some Campaigns',
        label: 'Led Some Campaigns',
    },
    {
        value: 'Expert',
        label: 'Expert',
    },
]

export const advertiser_campaigns_influencer_management_challenges = [
    {
        value: 'Finding the right influencers',
        label: 'Finding the right influencers',

    },
    {
        value: 'Managing multiple campaigns',
        label: 'Managing multiple campaigns',
    },
    {
        value: 'Tracking performance metrics',
        label: 'Tracking performance metrics',
    },
    {
        value: 'Communication with influencers',
        label: 'Communication with influencers',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]


export const influencer_content_creating_experience = [
    {
        value: 'Just Starting Out',
        label: 'Just Starting Out',

    },
    {
        value: 'Growing My Community',
        label: 'Growing My Community',
    },
    {
        value: 'Already monetizing',
        label: 'Already monetizing',
    },
    {
        value: 'Looking to scale',
        label: 'Looking to scale',
    },
]

export const influencer_platform_primary_goals = [
    {
        value: 'Connect with Brands',
        label: 'Connect with Brands',

    },
    {
        value: 'Grow My Audience',
        label: 'Grow My Audience',
    },
    {
        value: 'Monetize My Content',
        label: 'Monetize My Content',
    },
    {
        value: 'Learn and Improve',
        label: 'Learn and Improve',
    },
    {
        value: 'Have in-depth analytics on my account',
        label: 'Have in-depth analytics on my account',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]

export const creator_next_steps = [
    {
        value: 'Launch a New Content Series',
        label: 'Launch a New Content Series',
    },
    {
        value: 'Partner with More Brands',
        label: 'Partner with More Brands',
    },
    {
        value: 'Increase My Follower Count',
        label: 'Increase My Follower Count',
    },
    {
        value: 'Improve Engagement',
        label: 'Improve Engagement',
    },
    {
        value: 'Other',
        label: 'Other',
    },
]