/* eslint-disable */

const initState = {
    isShowin: false, 
    isActiveSideNav: false,
    profile: null,
    searchFilters: null as unknown,
    rules: [],
    role: null,
    userInfo: {},
    profilePicture: "https://cdn.pixabay.com/photo/2013/07/13/12/07/avatar-159236_1280.png",
    categories: [],
    countries: [],
    businessSectors: [],
    isValidated: false,
    isCompleted: false,
    isVerified: false,
    saveProfiles: [],
    notificationsCount:0,
    notifications:[],
    read_notification_hash:'',
    delete_notification_hash:'',
    isTCFclosed: false,

}

export const global = {
    namespaced: true,
    state: initState,
    actions: {},
    mutations: {
        profile(state: any, profile: any) {
            state.profile = profile
        },
        role(state: any, role: string) {
            state.role = role
        },
        rules(state: any, rules: any) {
            state.rules = rules
        },
        searchFilters(state: any, filters: object) {
            state.searchFilters = filters
        },
        profilePicture(state: any, profilePicture: string) {
            state.profilePicture = profilePicture
        },
        userInfo(state: any, userInfo: object) {
            state.userInfo = userInfo
        },
        categories(state: any, categories: any) {
            state.categories = categories
        },
        countries(state: any, countries: any) {
            state.countries = countries
        },
        businessSectors(state: any, businessSectors: any) {
            state.businessSectors = businessSectors
        },
        isValidated(state: any, isValidated: boolean) {
            state.isValidated = isValidated
        },
        isCompleted(state: any, isCompleted: boolean) {
            state.isCompleted = isCompleted
        },
        isVerified(state: any, isVerified: boolean) {
            state.isVerified = isVerified
        },
        isActiveSideNav(state: any, isActiveSideNav: boolean) {
            if (isActiveSideNav) {
                document.body.classList.add('has-active-sidebar');
                document.body.classList.remove('has-inactive-sidebar');
            } else {
                document.body.classList.add('has-inactive-sidebar');
                document.body.classList.remove('has-active-sidebar');
            }
            state.isActiveSideNav = isActiveSideNav
        },
        toggleFeebackShowin(state: any, isShowin: boolean) {
            if (isShowin) {
                document.body.classList.add('feedback-is-showin');
            } else {
                document.body.classList.remove('feedback-is-showin');
            }
            state.isShowin = isShowin
        },
        notificationsCount(state: any, notificationsCount: number) {
            state.notificationsCount = notificationsCount
        },
        notifications(state: any, notifications: number) {
            state.notifications = notifications
        },
        read_notification_hash(state: any, read_notification_hash: number) {
            state.read_notification_hash = read_notification_hash
        },
        delete_notification_hash(state: any, delete_notification_hash: number) {
            state.delete_notification_hash = delete_notification_hash
        },
        isTCFclosed(state: any, isTCFclosed: boolean) {
            state.isTCFclosed = isTCFclosed
        },
    },
    getters: {
        getProfile: (state: any) => {
            return state.profile
        },
        getRole: (state: any) => {
            return state.role
        },
        getRules: (state: any) => {
            return state.rules
        },
        getSearchFilters: (state: any) => {
            return state.searchFilters
        },
        getProfilePicture: (state: any) => {
            return state.profilePicture
        },
        getUserInfo: (state: any) => {
            return state.userInfo
        },
        getCategories: (state: any) => {
            return state.categories
        },
        countries: (state: any) => {
            return state.countries
        },
        businessSectors: (state: any) => {
            return state.businessSectors
        },
        isValidated(state: any) {
            return state.isValidated
        },
        isCompleted(state: any) {
            return state.isCompleted
        },
        isVerified(state: any) {
            return state.isVerified
        },
        getProfiles(state: any) {
            return state.saveProfiles
        },
        getNotificationsCount(state: any) {
            return state.notificationsCount
        },
        getNotifications(state: any) {
            return state.notifications
        },
        getReadNotificationHash(state: any) {
            return state.read_notification_hash
        },
        getDeleteNotificationHash(state: any) {
            return state.delete_notification_hash
        },
        isTCFclosed(state: any) {
            return state.isTCFclosed
        },
    }
}
