import { getConfigValue } from "./config";
import moment from "moment";

const BASE_API_URL = getConfigValue('BASE_API_URL');
const NODE_ENV = getConfigValue("NODE_ENV")

export const getBaseApiUrl = (): string => {
    return process.env.VUE_APP_BASE_API_URL;
}

// export const getFileUrlById = ({ fileId, size = 'full', ext = 'jpg' }) => {
//     if (NODE_ENV === "dev" || NODE_ENV === "local") {
//         return `https://api.tawa.digital/file/thumb/${size}/${fileId}.${ext}`
//     }
//     return `https://api.tawa.digital/file/thumb/${size}/${fileId}.${ext}`
//     //return `${BASE_API_URL}/file/thumb/${size}/${fileId}.${ext}`
// }

export const getFileUrlById = ({ fileId }): string =>
    process.env.VUE_APP_BASE_API_URL + `/v2/media/full/${fileId!}.jpg`;

export const getPdfFileUrlById = ({ fileId, size = 'full', ext = 'pdf' }) => {
    if (NODE_ENV === "dev" || NODE_ENV === "local") {
        return `${BASE_API_URL}/v2/media/${size}/${fileId}.${ext}`
    }
    return `${BASE_API_URL}/v2/media/${size}/${fileId}.${ext}`
    //return `${BASE_API_URL}/file/thumb/${size}/${fileId}.${ext}`
}

export const getPdfFileUrlByToken = ({ token, action = 'view' }): string =>
    process.env.VUE_APP_BASE_API_URL + `/v2/media/full/${token!}.pdf?action=${action}`;

export const getAnyFileUrlByToken = ({ token, ext, action = 'view', }): string =>
    process.env.VUE_APP_BASE_API_URL + `/v2/media/full/${token!}.${ext}?action=${action}`;

export const getAnyFileUrlById = ({ id, ext, action = 'view', }): string =>
    process.env.VUE_APP_BASE_API_URL + `/v2/media/full/${id!}.${ext}?action=${action}`;

export const getFileUrlByToken = ({ token, size = 'full', ext = 'jpg' }): string => {
    /* if (NODE_ENV === "dev" || NODE_ENV === "local") {
        return `https://api.tawa.digital/v2/media/${size}/${token}.${ext}`
    } */
    return `${BASE_API_URL}/v2/media/${size}/${token}.${ext}`
    //return `http://localhost:3000/v2/media/${size}/${token}.${ext}`
    //return `https://api.tawa.digital/v2/media/${size}/${token}.${ext}`
    //return `${BASE_API_URL.replace('/v2', '')}/v2/media/${size}/${token}.${ext}`
}

export const getAttachementByToken = ({ token, ext }): string => {
    /* if (NODE_ENV === "dev" || NODE_ENV === "local") {
        return `https://api.tawa.digital/v2/media/${size}/${token}.${ext}`
    } */
    return `${BASE_API_URL}/v2/mediaV2/${token}.${ext}`
    //return `http://localhost:3000/v2/media/${size}/${token}.${ext}`
    //return `https://api.tawa.digital/v2/media/${size}/${token}.${ext}`
    //return `${BASE_API_URL.replace('/v2', '')}/v2/media/${size}/${token}.${ext}`
}



export const isMediaQueryMobile = () => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    return (mediaQuery.matches);
}

export const isMediaQueryDesktop = () => {
    const mediaQuery = window.matchMedia('(min-width: 768px)');
    return (mediaQuery.matches);
}

export const sortShortListItemsBycreatedAt = (shortLists) => {
    return shortLists?.sort((sh1, sh2) =>
        moment(sh1.created_at).isBefore(moment(sh2.created_at))
            ? 1
            : moment(sh1.created_at).isAfter(moment(sh2.created_at))
                ? -1
                : 0
    );
};

export const sortListItemsBycreatedAt = (list) => {
    return list?.sort((item_1, item_2) =>
        moment(item_1.created_at).isBefore(moment(item_2.created_at))
            ? 1
            : moment(item_1.created_at).isAfter(moment(item_2.created_at))
                ? -1
                : 0
    );
};


export const sortCampaignsBycreatedAt = (campaigns) => {
    return campaigns?.sort((camp1, camp2) =>
        moment(camp1.created_at).isBefore(moment(camp2.created_at))
            ? 1
            : moment(camp2.created_at).isAfter(moment(camp2.created_at))
                ? -1
                : 0
    );
};

export const sortShortListItemsByCountItems = (shortLists) => {
    return shortLists?.sort((sh1, sh2) =>
        sh1.count_items < sh2.count_items
            ? 1
            : sh1.count_items > sh2.count_items
                ? -1
                : 0
    );
};
export const sortCampaignsByStartDate = (campaigns) => {
    return campaigns?.sort((camp1, camp2) =>
        moment(camp1.start_date_at).isAfter(moment(camp2.start_date_at))
            ? 1
            : moment(camp1.start_date_at).isBefore(moment(camp2.start_date_at))
                ? -1
                : 0
    );
};

export const sortCampaignsByRelevance = (campaigns, userCategories) => {
    return campaigns?.sort((camp1, camp2) => {
        const relevance1 = getRelevanceScore(camp1.categories, userCategories);
        const relevance2 = getRelevanceScore(camp2.categories, userCategories);

        return relevance2 - relevance1;
    });
};

const getRelevanceScore = (campaignCategories, userCategories) => {
    // Calculate relevance score based on matching categories
    return campaignCategories.filter(category => userCategories.includes(category)).length;
};

export const getEnvAllowedEmails = () => {
    return {
        env: process.env.VUE_APP_NODE_ENV,
        allowed_emails: process.env.ALLOWED_EMAILS.split(','),
    }
}

export const isEmailAllowed = (email: string) => {
    if (process.env.VUE_APP_NODE_ENV != 'production' && process.env.VUE_APP_NODE_ENV != 'prod' && process.env.VUE_APP_NODE_ENV != 'beta') {
        const allowedEmails = process.env.ALLOWED_EMAILS.split(',');
        return allowedEmails.includes(email);
    } else {
        return true;
    }
}


export const getNodeEnv = (): string =>
    process.env.VUE_APP_NODE_ENV ;